<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="700">
      <v-card>
        <v-card-title class="headline">
          {{ $t("starting_order.title_delete") }}
        </v-card-title>
        <v-card-text>
          <hr />
          <div class="row">
            <div class="col-md-6">
              <p>
                <i class="ti-info mr-2 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("starting_order.source_financing") }}</span>
                  {{ item.fiscalFoundKey }}
                </strong>
              </p>
            </div>
            <div class="col-md-6">
              <p>
                <i class="ti-package mr-2 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("starting_order.causes") }}</span>
                  {{ item.productKey }}
                </strong>
              </p>
            </div>
          </div>
          <p class="pb-3">
            <i class="ti-align-center mr-2 text-fmedical-blue"></i>
            <strong>
              <span> {{ $t("starting_order.description") }}</span>
              {{ item.productDescription }}
            </strong>
          </p>
          <div class="row">
            <div class="col-md-3">
              <p>
                <i class="ti-layout-grid2 mr-2 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("starting_order.lot") }}</span>
                  {{ item.lot }}</strong
                >
              </p>
            </div>
            <div class="col-md-5">
              <p>
                <i class="ti-calendar mr-2 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("starting_order.expiration") }}</span>
                  {{ item.expirationDate }}
                </strong>
              </p>
            </div>
            <div class="col-md-4">
              <p>
                <i class="ti-layers mr-2 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("starting_order.quantity") }}</span>
                  {{ item.quantity }}
                </strong>
              </p>
            </div>
          </div>
          <hr />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <button
            class="btn btn-outline-success mr-4 mt-4"
            @click="deleteRecord()"
          >
            <i class="feather icon-check mr-1"></i> {{ $t("global.accept") }}
          </button>
          <button class="btn btn-outline-danger mt-4" @click="dialog = false">
            <i class="feather icon-x mr-1"></i> {{ $t("global.cancel") }}
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      item: {
        fiscalFund: {
          key: "",
        },
        products: {},
        orderStock: {
          quantity: 0,
        },
      },
      dialog: false,
    };
  },
  mounted() {},
  methods: {
    reloadComponent(item) {
      this.dialog = true;
      this.item = item;
    },
    reloadComponentClose() {
      this.dialog = false;
      this.item = {
        fiscalFund: {
          key: "",
        },
        products: {},
        orderStock: {
          quantity: 0,
        },
      };
    },
    deleteRecord() {
      this.$emit("deleteRecord", this.item);
    },
  },
};
</script>
