<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <AlertMessageComponent ref="alertMessageComponent" />
    <div class="row">
      <div class="col-12 pt-0">
        <h6>
          <v-icon class="pb-1">mdi-numeric-1-box-outline</v-icon>
          {{ $t("dispensing.text_form_info") }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <i class="fa fa-question-circle fa-help mr-2 pl-0" v-bind="attrs" v-on="on"></i>
            </template>
            <div class="text-center pt-2 pb-0">
              <span class="overline">Reglas de folio</span>
              <p class="caption font-weight-thin pt-0">(Solo se permiten ingresar números)</p>
            </div>
            <p class="caption px-5">
              <span class="font-weight-bold">{{ $t('label.ordinary') }}:</span>
            <ul class="pl-5">
              <li class="caption">Longitud mínima de {{ minOrdinaryLength }} caracteres y un máximo de {{
                maxOrdinaryLength }}. <br> Ejemplo:
                <table class="table">
                  <thead>
                    <tr>
                      <th>0001001011</th>
                    </tr>
                  </thead>
                </table>
              </li>
            </ul>
            </p>
            <p class="caption px-5">
              <span class="font-weight-bold">{{ $t('label.electronic') }}:</span>
            <ul class="pl-5">
              <li class="caption pb-2">

                2021: Longitud de {{ minSrsLength }} caracteres.<br> Ejemplo:
                <table class="table">
                  <thead>
                    <tr>
                      <th>[ 21 ] &nbsp 010010</th>
                    </tr>
                  </thead>
                </table>
              </li>
              <li class="caption pb-2">2022: Longitud mínima de {{ minSrsLength + 2 }} caracteres y máximo de {{
                maxSrsLength }}.<br> Ejemplo:
                <table class="table">
                  <thead>
                    <tr>
                      <th>[ 22 ] &nbsp 01001011</th>
                    </tr>
                  </thead>
                </table>
              </li>
              <li class="caption"> 2023 y 2024: Longitud de {{ minSrsLength + 2 }} caracteres. <br> Ejemplo:
                <table class="table">
                  <thead>
                    <tr>
                      <th>[ 23 ] [ E ] &nbsp 01001011</th>
                    </tr>
                    <tr>
                      <th>[ 24 ] [ E ] &nbsp 01001011</th>
                    </tr>
                  </thead>
                </table>
              </li>
            </ul>
            </p>
          </v-tooltip>
        </h6>
      </div>

      <div class="col-12 col-lg-4 pt-0">
        <validation-provider ref="prescriptionKey" v-slot="{ errors }" name="dispensing_type" rules="required">
          <v-select v-model="select" :items="types" label="Seleccione un tipo" outlined item-text="text" item-value="val"
            required :error-messages="errors" return-object></v-select>
        </validation-provider>
      </div>

      <div v-if="select.value == 1" class="col-12 col-lg-8">
        <div class="row">
          <div class="col-11 pt-0 pr-0 ">
            <validation-provider v-slot="{ errors }" name="folio"
              :rules="`required|min:${minOrdinaryLength}|max:${maxOrdinaryLength}|numeric`">
              <v-text-field v-model="ordinary" v-on:keyup.enter="validateFolio(invalid, select.value)"
                :suffix="ordinary == '' ? '' : 'enter'" class="br" :label="$t('label.folio')"
                :placeholder="$t('label.ordinary')" outlined counter :maxlength="maxOrdinaryLength"
                :error-messages="errors" autofocus :color="valid ? 'green' : ''">
                <template v-slot:append>
                  <span class="pt-2">
                    <i class="feather icon-corner-down-left" v-show="ordinary != ''"></i>
                  </span>

                </template></v-text-field>
            </validation-provider>
          </div>
          <div class="col-1">
            <v-progress-circular v-if="loadingFolioValidation" size="24" color="#4c8c6c" indeterminate />
            <v-icon v-if="!loadingFolioValidation" :color="folioValidation" v-text="validatePrescriptionIcon" />
          </div>
        </div>
      </div>
      <div v-if="select.value == 3" class="col-12 col-lg-8">


        <div class="row">
          <div class="col-2 pr-0 pr-md-3 pt-0">
            <validation-provider v-slot="{ errors }" name="current_year"
              :rules="`required|min_value:${minYearValue}|max_value:${maxYearValue}|numeric`">
              <v-text-field v-model="yearSRS" :label="$t('label.year')" :min="minYearValue" :max="maxYearValue" outlined
                type="number" :error-messages="errors"></v-text-field>
            </validation-provider>
          </div>
          <div v-if="yearSRS >= 23" class="col-12 col-lg-2 pt-0">
            <validation-provider v-slot="{ errors }" name="folio" rules="required">
              <v-select v-model="letterMedical" :items="letterMedicalTypes" outlined item-text="text" item-value="val"
                required :error-messages="errors">
                <template v-slot:selection="data">
                  <v-icon large>
                    {{ letterMedicalIcon(data.item.value) }}
                  </v-icon>
                </template>
                <template v-slot:item="data">
                  {{ data.item.value }}
                </template>
              </v-select>
            </validation-provider>
          </div>

          <div class="col-7 pt-0 pr-0">
            <validation-provider v-slot="{ errors }" name="folio" :rules="`required|numeric|min:${yearSRS != 21 ? minSrsLength + 2 : minSrsLength
              }|max:${yearSRS == 22 ? maxSrsLength : yearSRS == 21 ? minSrsLength : minSrsLength + 2}`">
              <v-text-field v-model="srs" v-on:keyup.enter="validateFolio(invalid, select.value)"
                :suffix="srs == '' ? '' : 'enter'" class="br" :label="$t('label.folio')"
                :placeholder="$t('label.electronic')" outlined counter
                :maxlength="yearSRS == 22 ? maxSrsLength : yearSRS == 21 ? minSrsLength : minSrsLength + 2"
                :error-messages="errors" :disabled="yearSRS > maxYearValue || yearSRS < minYearValue ? true : false"
                autofocus :color="valid ? 'green' : ''">
              </v-text-field>
            </validation-provider>
          </div>
          <div class="col-1">
            <v-progress-circular v-if="loadingFolioValidation" size="24" color="#4c8c6c" indeterminate />
            <v-icon v-if="!loadingFolioValidation" :color="folioValidation" v-text="validatePrescriptionIcon" />
          </div>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
/**
 * @component DispensingValidationComponent
 * @desc Valida el tipo de folio de dispensación.
 * @author Yair Alvarez
 */

import moment from "moment";
import { SwalInfo } from "@/other/utils/SwalAlert.js";

export default {
  props: {
    /** @vprop {Function} [validatePrescriptionKey]       -Validates folio.*/
    /** @vprop {String} [folioValidation = '']            -Icon color.*/
    /** @vprop {String} [validatePrescriptionIcon = '']   -Icon type.*/
    /** @vprop {Boolean} [loadingFolioValidation = false] -Shows icon.*/
    /** @vprop {Object} [modelDispensing = {}]            -Dispensing model.*/
    /** @vprop {Object} [typeMedicalPrescription = {}]    -Prescription type.*/

    validatePrescriptionKey: Function,
    folioValidation: {
      required: true,
      default: "",
    },
    validatePrescriptionIcon: {
      required: true,
      default: "",
    },
    loadingFolioValidation: {
      type: Boolean,
      required: true,
    },
    modelDispensing: {
      type: Object,
      required: true,
    },
    typeMedicalPrescription: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      /** * @member {Array|Object} types        - Types values. / Valores de tipo. */
      /** * @member {Number} ordinary           - Ordinary type. / Tipo ordinario. */
      /** * @member {Number} srs                - Srs type. / Tipo srs. */
      /** * @member {Object} select             - Selected type model. / Modelo de tipo seleccionado. */
      /** * @member {Number} yearSRS            - Selected srs year. / Año srs seleccionado. */
      /** * @member {Number} currentYear        - Current year. / Año en curso. */
      /** * @member {Number} minOrdinaryLength  - Ordinary type minumum length. / Longitud minima de tipo ordinario. */
      /** * @member {Number} maxOrdinaryLength  - Ordinary type maximum length. / Longitud maxima de tipo ordinario. */
      /** * @member {Number} minSrsLength          - Srs type minumum length. / Longitud minima de tip srs. */
      /** * @member {Number} maxSrsLength          - Srs type maximum length. / Longitud maxima de tipo srs. */
      /** * @member {Number} minYearValue       - Minimun year value. / Valor de año minimo. */
      /** * @member {Number} maxYearValue       - Maximum year value. / Valor de año maximo. */
      /** * @member {Boolean} valid             - Folio validation response. / Respuesta de validacion de folio. */

      types: [
        { text: "Folio Ordinario", value: 1 },
        { text: "Folio Electrónico (SRS)", value: 3 },
      ],
      ordinary: null,
      srs: null,
      select: { value: null },
      yearSRS: String(moment().year()).slice(-2),
      currentYear: String(moment().year()).slice(-2),
      minOrdinaryLength: 10,
      maxOrdinaryLength: 14,
      minSrsLength: 6,
      maxSrsLength: 12,
      minYearValue: 21,
      maxYearValue: 99,
      valid: false,
      letterMedical: "E",
      letterMedicalTypes: [
        { text: "E", value: "E" },
        { text: "F", value: "F" },
      ],
      iconLetter: "mdi mdi-alpha-e",
    };
  },
  watch: {
    // Sets ordinary value to dispensing model
    ordinary() {
      if (this.ordinary != null) {
        this.modelDispensing.prescriptionKey = this.ordinary;
        this.typeMedicalPrescription.value = false;
      }
    },
    letterMedical() {
      this.srsValidation();
    },
    // Sets srs value to dispensing model
    srs() {
      this.srsValidation();
    },
    // Shows year validation alert
    yearSRS() {
      if (this.yearSRS > this.currentYear) this.alertYear();
      this.srsValidation();
    },
  },
  methods: {
    letterMedicalIcon(key) {
      switch (key) {
        case "E":
          this.letterMedical = "E";
          return this.iconLetter = "mdi mdi-alpha-e";
        case "F":
          this.letterMedical = "F";
          return this.iconLetter = "mdi mdi-alpha-f";
      }
      return "";
    },
    srsValidation() {
      if ( this.srs != null ) {
        let srs = '';
        if (this.yearSRS >= 23) 
          srs = this.yearSRS + this.letterMedical + this.srs;
        else srs = this.yearSRS + this.srs;
        this.modelDispensing.prescriptionKey = srs;
        this.typeMedicalPrescription.value = true;
      }
    },
    /**
     * Sets data to ordinary or srs model (Edit action). / Establece los datos para el modelo ordinario o srs (Accion Editar).
     * Calls setSRS function. / Llama la funcion setSRS
     * @method
     * @param {Object} data - Prescription model.
     */
    reloadComponent(data) {
      if (data.typeMedicalPrescription == 1) {
        this.select = this.types[0];
        this.ordinary = data.prescriptionKey;
        this.modelDispensing.prescriptionKey = this.ordinary;
      }
      if (data.typeMedicalPrescription == 3) {
        this.setSRS(data.prescriptionKey);
        this.modelDispensing.prescriptionKey = data.prescriptionKey;
      }
    },

    /**
     * Sets format for srs type. / Establece el formato para el tipo srs.
     * @method
     * @param {Object} data - Folio.
     */
    setSRS(data, toSrs) {
      this.select = this.types[1];
      this.yearSRS = data.slice(0, 2);
      if (this.yearSRS >= 23) {
        toSrs ? this.letterMedical = "E" : this.letterMedical = data.slice(2, 3);
        toSrs ? this.srs = data.slice(2 , 10) : this.srs = data.slice(3 , 11)   
      } else if (this.yearSRS == 22) this.srs = data.slice(2, 16);
      else this.srs = data.slice(2, 11);

    },
    /**
     * Resets data when "Return" button is clicked. / Restablece datos al dar click en el boton "Regresar".
     * @method
     */
    clearInput() {
      this.ordinary = null;
      this.srs = null;
      this.yearSRS = this.currentYear;
      this.select = { value: null };
      this.valid = false;
    },

    /**
     * Validate dispensing types form. / Valida el formulario de tipos de dispensacion.
     * @method
     */
    async validateForm() {
      let isValid = await this.$refs.observer.validate();
      return isValid;
    },

    /**
     * Sets dispensing type and call validate type functions. / Establece el tipo de dispensacion y llama la funcion de validar tipo.
     * @method
     */
    validateFolio(invalid, type) {
      if (!invalid) {
        switch (type) {
          case 1:
            this.typeMedicalPrescription.value = false;
            this.validateOrdinary();
            break;
          case 3:
            this.typeMedicalPrescription.value = true;
            this.validateSRS();
            break;
          default:
            break;
        }
      }
    },

    /**
     * Validate ordinary type dispensing. / Valida dispensacion de tipo ordinario.
     * Calls alertType function if ordinary value starts > 21./ LLama la funcion alertType si ordinario empieza con un valor > 21.
     * Calls validatePrescriptionKey function./ Llama la funcion validatePrescriptionKey.
     * @method
     */
    async validateOrdinary() {
      if (this.ordinary.slice(0, 2) > this.minYearValue) {
        this.alertType();
      } else {
        this.modelDispensing.prescriptionKey = this.ordinary;
        this.valid = await this.validatePrescriptionKey();
      }
    },

    /**
     * Validate srs type dispensing. / Valida dispensacion de tipo srs.
     * Calls validatePrescriptionKey function./ Llama la funcion validatePrescriptionKey.
     * @method
     */
    async validateSRS() {
      let srs = "";
      if (this.yearSRS >= 23)
        srs = this.yearSRS + this.letterMedical + this.srs;
      else srs = this.yearSRS + this.srs;
      this.modelDispensing.prescriptionKey = srs;
      this.valid = await this.validatePrescriptionKey();
    },

    /**
     * Shows alert to change or validate type dispensing. / Muestra alerta para cambiar o validar tipo de dispensacion.
     * Calls validatePrescriptionKey function./ Llama la funcion validatePrescriptionKey.
     * @method
     */
    async alertType() {
      const { isConfirmed } = await this.$SwalConfirm.fire({
        icon: "warning",
        title: "",
        html: "El folio ingresado puede ser un Folio Electrónico (SRS)<b>",
        cancelButtonText:
          '<i class="feather icon-alert-circle mr-1"></i> Continuar sin validar',
        confirmButtonText:
          '<i class="feather icon-check mr-1"></i> Cambiar a Folio Electrónico',
      });
      if (!isConfirmed) {
        this.modelDispensing.prescriptionKey = this.ordinary;
        this.valid = await this.validatePrescriptionKey();
      } else {
        this.setSRS(this.ordinary, true);
      }
    },

    async alertYearEdit(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },

    /**
     * Shows alert if selected year > current year. / Muestra alerta si el año seleccionado es > al año en curso.
     * @method
     */
    async alertYear() {
      await SwalInfo.fire({
        icon: "warning",
        confirmButtonText: '<i class="feather icon-check mr-1"></i> Aceptar',
        html: "<b>No es posible ingresar años mayores al actual</b>",
      });
      this.yearSRS = this.currentYear;
    },
  },
};
</script>
<style scoped>
::v-deep.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 9px !important;
}

.fa-help {
  color: rgb(0, 0, 0);
}

::v-deep.swal2-confirm {
  margin-bottom: 15px !important;
}
</style>