var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card border-none"},[_c('div',{staticClass:"card-body pt-0"},[_c('DispensingValidationComponent',{ref:"prescriptionKey",attrs:{"modelDispensing":_vm.modelDispensing,"loadingFolioValidation":_vm.loadingFolioValidation,"folioValidation":_vm.folioValidation,"validatePrescriptionIcon":_vm.validatePrescriptionIcon,"typeMedicalPrescription":_vm.typeMedicalPrescription,"validatePrescriptionKey":_vm.validatePrescriptionKey}}),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.actionForm.apply(null, arguments)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formValidate),expression:"formValidate"}],staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('hr'),_c('h6',[_c('v-icon',{staticClass:"pb-1",domProps:{"textContent":_vm._s('mdi-numeric-2-box-outline')}}),_vm._v(" "+_vm._s(_vm.$t("dispensing.text_pre_info"))+" ")],1)]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3"},[_c('h6',[_vm._v("¿Farmacia 24 horas?")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_c('v-switch',{attrs:{"disabled":_vm.sending,"label":_vm.modelDispensing.fullTime
                        ? _vm.$t('dispensing.yes')
                        : _vm.$t('dispensing.no')},model:{value:(_vm.modelDispensing.fullTime),callback:function ($$v) {_vm.$set(_vm.modelDispensing, "fullTime", $$v)},expression:"modelDispensing.fullTime"}})],1)])]),_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"doctor_","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"no-data-text":_vm.$t('label.no_results'),"items":_vm.doctorList,"disabled":_vm.sending,"auto-select-first":"","error-messages":errors,"label":_vm.$t('label.doctor'),"required":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"color":"info"},domProps:{"textContent":_vm._s('mdi-plus-circle-outline')},on:{"click":function($event){_vm.dialogDoctor = true}}})]},proxy:true}],null,true),model:{value:(_vm.modelDispensing.doctor.idDoctor),callback:function ($$v) {_vm.$set(_vm.modelDispensing.doctor, "idDoctor", $$v)},expression:"modelDispensing.doctor.idDoctor"}})]}}],null,true)})],1),_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"number_file","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"oninput":"this.value = this.value.toUpperCase()","disabled":_vm.sending,"counter":20,"maxlength":20,"error-messages":errors,"label":_vm.$t('label.number_file'),"required":""},model:{value:(_vm.modelDispensing.medicalRecord),callback:function ($$v) {_vm.$set(_vm.modelDispensing, "medicalRecord", $$v)},expression:"modelDispensing.medicalRecord"}})]}}],null,true)})],1),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"},[_c('validation-provider',{attrs:{"name":"date_prescription","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"min":_vm.actionState == 3 ? null : _vm.minCurreDate,"max":_vm.currentDate,"disabled":_vm.sending,"label":_vm.$t('label.date_prescription'),"type":"date","error-messages":errors,"required":""},on:{"keypress":_vm.keypresDate},model:{value:(_vm.modelDispensing.datePrescription),callback:function ($$v) {_vm.$set(_vm.modelDispensing, "datePrescription", $$v)},expression:"modelDispensing.datePrescription"}})]}}],null,true)})],1),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"},[_c('validation-provider',{attrs:{"name":"date_dispensing","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"min":_vm.actionState == 3 ? null : _vm.minCurreDate,"max":_vm.currentDate,"disabled":_vm.sending,"label":_vm.$t('label.date_dispensing'),"type":"date","error-messages":errors,"required":""},on:{"keypress":_vm.keypresDate},model:{value:(_vm.modelDispensing.dateDispensing),callback:function ($$v) {_vm.$set(_vm.modelDispensing, "dateDispensing", $$v)},expression:"modelDispensing.dateDispensing"}})]}}],null,true)})],1),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3"},[_c('h6',[_vm._v(_vm._s(_vm.$t("dispensing.text_medical_unit")))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_c('v-switch',{attrs:{"disabled":_vm.sending,"label":_vm.isMedicalUnit
                        ? _vm.$t('dispensing.yes')
                        : _vm.$t('dispensing.no')},model:{value:(_vm.isMedicalUnit),callback:function ($$v) {_vm.isMedicalUnit=$$v},expression:"isMedicalUnit"}})],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMedicalUnit),expression:"!isMedicalUnit"}],staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9"},[_c('v-autocomplete',{attrs:{"items":_vm.branchOfficeList,"disabled":_vm.sending,"label":_vm.$t('label.unit_medical')},model:{value:(
                  _vm.modelDispensing.branchOfficeMedicalUnit.idBranchOffice
                ),callback:function ($$v) {_vm.$set(_vm.modelDispensing.branchOfficeMedicalUnit, "idBranchOffice", $$v)},expression:"\n                  modelDispensing.branchOfficeMedicalUnit.idBranchOffice\n                "}})],1),_c('div',{staticClass:"col-12"},[_c('hr'),_c('h6',[_c('v-icon',{staticClass:"pb-1",domProps:{"textContent":_vm._s('mdi-numeric-3-box-outline')}}),_vm._v(" "+_vm._s(_vm.$t("dispensing.info_beneficiary_text"))+" ")],1)]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5"},[_c('validation-provider',{attrs:{"name":"name","rules":"required|max:50|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.sending,"counter":50,"oninput":"this.value = this.value.toUpperCase()","maxlength":50,"error-messages":errors,"label":_vm.$t('label.name'),"required":""},model:{value:(_vm.modelDispensing.name),callback:function ($$v) {_vm.$set(_vm.modelDispensing, "name", $$v)},expression:"modelDispensing.name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7"},[_c('validation-provider',{attrs:{"name":"lastname","rules":"required|max:100|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.sending,"counter":100,"oninput":"this.value = this.value.toUpperCase()","maxlength":100,"error-messages":errors,"label":_vm.$t('label.lastname'),"required":""},model:{value:(_vm.modelDispensing.lastName),callback:function ($$v) {_vm.$set(_vm.modelDispensing, "lastName", $$v)},expression:"modelDispensing.lastName"}})]}}],null,true)})],1),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"},[_c('validation-provider',{attrs:{"name":"curp","rules":"required|alpha_num|min:18|max:18"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.sending,"counter":18,"oninput":"this.value = this.value.toUpperCase()","maxlength":18,"error-messages":errors,"label":_vm.$t('label.curp'),"required":""},model:{value:(_vm.modelDispensing.curp),callback:function ($$v) {_vm.$set(_vm.modelDispensing, "curp", $$v)},expression:"modelDispensing.curp"}})]}}],null,true)})],1),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3"},[_c('validation-provider',{attrs:{"name":"birth_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"max":_vm.currentDate,"disabled":_vm.sending,"label":_vm.$t('label.birth_date'),"type":"date","error-messages":errors,"required":""},on:{"keypress":_vm.keypresDate},model:{value:(_vm.modelDispensing.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.modelDispensing, "dateOfBirth", $$v)},expression:"modelDispensing.dateOfBirth"}})]}}],null,true)})],1),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3"},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"gender","error-messages":errors,"no-data-text":"Sin resultados","items":_vm.genderList,"disabled":_vm.sending,"label":_vm.$t('label.gender'),"required":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.value))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.value))+" ")]}}],null,true),model:{value:(_vm.modelDispensing.gender),callback:function ($$v) {_vm.$set(_vm.modelDispensing, "gender", $$v)},expression:"modelDispensing.gender"}})]}}],null,true)})],1),_c('div',{staticClass:"col-12 text-right"},[_c('button',{staticClass:"btn btn-outline-success",attrs:{"type":"submit","disabled":_vm.sending || invalid}},[_c('i',{staticClass:"ti-plus mr-2"}),_vm._v(" "+_vm._s(_vm.titleButton)+" ")])])])])]}}])})],1)]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"1000","persistent":""},model:{value:(_vm.dialogDoctor),callback:function ($$v) {_vm.dialogDoctor=$$v},expression:"dialogDoctor"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('small',{staticStyle:{"font-size":"100%"}},[_vm._v("Agregar nuevo doctor")])]),_c('v-container',[_c('AddDoctorComponent',{ref:"formAddDoctor",attrs:{"idUser":_vm.idUser,"idBranchOffice":_vm.idBranchOffice,"isModal":true},on:{"alert":_vm.alertDoctor,"closeModalDoctor":_vm.closeModalDoctor}})],1)],1)],1)],1),_c('DispensingModalConfirmComponent',{ref:"modalConfirm",attrs:{"status":_vm.modelDispensing.status},on:{"confirm":_vm.confirmModalPrescription}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }