<template>
    <v-dialog v-model="dialog"  fullscreen hide-overlay persistent transition="dialog-bottom-transition" eager>
        <v-card class="bg">
            <v-toolbar style=" background-color:#e9ecef;">
                <v-toolbar-title class="pt-4">
                    <p>
                        <i class="mdi mdi-clipboard-text mr-2"></i> {{title}}
                    </p>
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <div class="row container-center">
                <div style="padding-top: 50px;" class="col-md-12 col-sm-12 col-lg-12 text-center">
                    <transition name="fade">
                        <h2 class="card-title mb-4" v-if="show">
                            {{ question }}
                        </h2>
                    </transition>
                </div>

            </div>
             <div class="container-center pt-5">
            <div v-if="typeQuestion === 'Abierta'">
                <QuestionOpen :saveAnswers="saveAnswers" />
            </div>
            <div v-if="typeQuestion === 'Cerrada' || typeQuestion === 'Rango' ">
                <QuestionFace :saveAnswers="saveAnswers" :answersQuestion="answersQuestion" :answersOption="answersOption" />
            </div>
            <div v-if="typeQuestion === 'Multiple' ">
                <QuestionSelect :saveAnswers="saveAnswers" :answersQuestion="answersQuestion" :answersOption="answersOption" />
            </div>
            <div v-if="typeQuestion === 'Matriz(No)' ">
                <QuestionOption :saveAnswers="saveAnswers" :answersQuestion="answersQuestion" :answersOption="answersOption" />
            </div>
            </div>

            <div class="container-center pt-5">
                <div class="col-md-12 col-sm-12 col-lg-12 text-center">
                    <h4>
                        <transition name="fade">
                            <span v-if="show">
                                {{ questionNumber + 1 }}
                            </span>
                        </transition>
                        <span style="font-size: 80%"> de </span>
                        <span>
                            {{ limitQuestion + 1 }}
                        </span>
                    </h4>
                </div>

                <div class="col-md-12 col-sm-12 col-lg-12 text-center" >
                    <template v-if="limitQuestion !== questionNumber">
                        <button size="lg" class="btn btn-outline-success" @click="nextQuestion()">
                            Siguiente <span class="mdi mdi-chevron-double-right"></span>
                        </button>
                    </template>
                    <template v-if="limitQuestion === questionNumber">
                        <button size="lg" class="btn btn-info" @click="endQuestion()">
                            <span class="mdi mdi-clipboard-check mr-2"></span> Finalizar 
                        </button>
                    </template>
                </div>
                <div class="col-md-1 col-sm-1 col-lg-1 text-left"></div>
            </div>
            <v-footer class="footer" fixed>
                <v-col class="text-right" cols="12">
                    <button class=" text-right btn-transparent" @click="resetValues()">
                        ESC
                    </button>
                </v-col>
            </v-footer>
        </v-card>

    </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';
import {
    QuizRequest,MedicalPrescriptionRequest
} from "@/core/request";
import {
    UtilFront
} from "@/core";
import question from "@/assets/json/preguntas-su-01.json";
import QuestionFace from "@/components/quiz/QuestionFace"
import QuestionOpen from "@/components/quiz/QuestionOpen"
import QuestionSelect from "@/components/quiz/QuestionSelect"
import QuestionOption from "@/components/quiz/QuestionOption"



export default {
    components: {
        QuestionFace,
        QuestionOpen,
        QuestionSelect,
        QuestionOption
    },
    data() {
        return {
            //quiz
            idSurvey: null,
            questionNumber: 0,
            id_receta: null,
            quiz: [],
            title: "",
            question: "",
            answersOption: {}, // Guarda las diferentes opciones de las prenguntas, esta variable cambia su valor por cada pregunta.
            dialog: false,
            limitQuestion: 0,
            typeQuestion: '',
            idPrescription: '',
            loading: null,

            /* Respuestas */
            answersQuestion: null,
            answerApplication: [], // Variable donde se guardan las preguntas opción multiple
            answerOpen: [], // Variable donde se guardan las preguntas abiertas
            idQuestion: null,
            show: true, // Para mostrar y ocultar el modal de encuestas
            data: {},
            dateApplication: null
        };
    },
    methods: {
        ...mapMutations('dispensing', 
            { 
                updatePrescription: 'UPDATE_ID_PRESCRIPTION',
                updateSurveyCount: 'UPDATE_SURVEY_COUNT'
            } 
        ),
        showQuiz( idPrescription ) {
            try {
                this.questionNumber = 0;
                this.answersQuestion = null;
                this.dialog = true;
                this.limitQuestion = question.data.questions.length - 1;
                this.quiz = question.data.questions;
                this.idSurvey = question.data.idSurvey;
                this.question = this.quiz[0].textQuestion;
                this.typeQuestion = this.quiz[0].typeQuestion;
                this.title = this.quiz[0].descriptionSubCategory;
                this.answersOption = this.quiz[0].answers;
                this.idQuestion = this.quiz[0].idQuestion;

                this.idPrescription = idPrescription;
            } catch (error) {
                this.$toast.error("Ocurrio un error, no se guardo la encuesta.");
                this.resetValues();
            }
        },
        closeQuiz() {
            this.dialog = false;
        },
        async nextQuestion() {
            try {
                /* Validacion de si la respuesta viene vacia. */
                if (this.answersQuestion !== null) {
                    /*  Guardar respuesta */
                    await this.addQuestion();

                    /* Asigna los valores de la siguiente pregunta */
                    this.question = this.quiz[this.questionNumber].textQuestion;
                    this.typeQuestion = this.quiz[this.questionNumber].typeQuestion;
                    this.answersOption = this.quiz[this.questionNumber].answers;
                    this.idQuestion = this.quiz[this.questionNumber].idQuestion;
                    this.answersQuestion = null;
                    /*  Animacion de encuesta */
                    setTimeout(() => (this.show = !this.show), 300);

                } else {
                    this.$toast.error("Debés seleccionar una respuesta.");
                }
            } catch (error) {
                this.$toast.error("Ocurrio un error, no se guardo la encuesta.");
                this.resetValues();
            }
        },
        saveAnswersServer() {
            this.data.answersApplication = this.answerApplication;
            this.data.idSurvey = this.idSurvey;
            this.data.answersOpen = this.answerOpen;

            //Muestro la pantalla de cargando
            this.loading = UtilFront.getLoadding();
            
            //Guardo la encuesta
            this.saveQuiz().then( ( response ) => {
                const { data } = response.data.data;

                //Guarda el id de la aplicacion en la receta
                this.saveSurveyAplicationInMedicalPrescription( data );

                this.updatePrescription( '' );
                this.updateSurveyCount( 0 );

            }).catch( ( error ) => {
                this.$toast.error( "Error al guardar la encuesta: " + error );
            }).finally( () => {
                this.loading.hide();
                this.resetValues();
            });

            return true; 
        },

        async saveQuiz() {
            return await QuizRequest.saveAnswersServer( 
                this.data
            );
        },

        //Esta funcion se encargar de guardar el id de la encuesta aplicada en la receta
        saveSurveyAplicationInMedicalPrescription( surveyAplication ){
            MedicalPrescriptionRequest.saveSurvey({
                idPrescription: this.idPrescription,
                surveyAplication: surveyAplication
            }).then( ( response ) => {
                const { success, message, data } = response.data.data;

                if ( success ) 
                    this.$toast.success("Encuesta registrada correctamente, ¡Gracias por su tiempo!");
                else 
                    this.$toast.error( "Error al vincular la encuesta con la receta: " + message );

            }).catch( ( error ) => {
                this.$toast.error( "Error al vincular la encuesta con la receta: " + error );
            }).finally( () => {
                //Elimino la pantalla de cargando
                this.loading.hide();
            }) ;

        },



        saveAnswers(answers) {
            this.answersQuestion = answers;
            this.idQuestion = this.quiz[this.questionNumber].idQuestion;
        },
        async endQuestion() {
            if (this.answersQuestion !== null) {
                /* Guardar respuesta */
                await this.addQuestion();
                await this.saveAnswersServer();
            } else {
                this.$toast.error("Debés seleccionar una respuesta.");
            }
        },
        async addQuestion() {
            switch (this.typeQuestion) {
                case 'Abierta':
                    /* Pregunta abierta */
                    this.answerOpen.push({
                        valueAnswer: this.answersQuestion,
                        question: {
                            idQuestion: this.idQuestion
                        }
                    });
                    break;
                case 'Cerrada':
                case 'Rango':
                case 'Matriz(No)':
                case 'Multiple':
                    this.answerApplication.push({
                        answerQuestion: {
                            idAnswerQuestion: this.answersQuestion
                        }
                    });
                    break;
            }
            if (this.typeQuestion !== 0) {
                this.questionNumber++;
                this.show = !this.show;
            } else {
                this.$toast.error("Ocurrio un error, no se guardo la encuesta.");
                this.resetValues();
            }
        },
        resetValues() {
            this.questionNumber = 0;
            this.dialog = false;
            this.limitQuestion = 0;
            this.answersQuestion = null
            this.answerApplication = []
            this.answerOpen = []
            this.idQuestion = null
            this.title = ""
            this.typeQuestion = 0
            this.show = true
        }
    },
};
</script>

<style scoped>
.bg {
    background: url(../../assets/images/quiz/fondo_quiz.jpg);
    background-size: cover;
    background-position: center;
}

.footer {
    background-color: transparent;
}

.btn-transparent {
    background: #fff !important;
    color: #fff;
    box-shadow: none;
}

.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}
</style>
