<template>
  <div>
    <div v-if="typeCalendar == 1">
      <vc-date-picker
        :locale="lang"
        color="green"
        v-model="dateMov"
        is-range
        popover-align="center"
      >
        <template v-slot="{ inputEvents }">
          <div class="flex justify-center items-center">
            <v-text-field
              :readonly="true"
              v-on="inputEvents.start"
              :value="modalDateRangeCalendar"
              :label="$t('label.date_range')"
              :append-outer-icon="
                modalDateRangeCalendarValid ? 'mdi-eraser' : 'mdi-calendar'
              "
              @click:append-outer="modalDateRangeClean"
              :disabled-input="modalDateRangeCalendarValid"
            ></v-text-field>
          </div>
        </template>
      </vc-date-picker>
    </div>
    <div v-if="typeCalendar == 2">
      <div class="col-md-12 text-center pt-0">
        <v-text-field
          :readonly="true"
          :value="modalDateSingleCalendar"
          :label="$t('label.select_date')"
          :append-outer-icon="
            modalDateRangeCalendarValid ? 'mdi-eraser' : 'mdi-calendar'
          "
          @click:append-outer="modalDateRangeClean"
          :disabled-input="modalDateRangeCalendarValid"
        />
        <vc-date-picker
          :locale="lang"
          color="green"
          v-model="dateMov"
          popover-align="center"
          :disabled-dates="{ weekdays: weekdaysArray }"
        />
      </div>
    </div>
    <div v-if="typeCalendar == 3">
      <div class="col-md-12 text-center pt-0">
        <v-text-field
          :readonly="true"
          :value="modalDateRangeCalendar"
          :label="$t('label.select_date')"
          :append-outer-icon="
            modalDateRangeCalendarValid ? 'mdi-eraser' : 'mdi-calendar'
          "
          @click:append-outer="modalDateRangeClean"
          :disabled-input="modalDateRangeCalendarValid"
        />
        <vc-date-picker
          :locale="lang"
          color="green"
          v-model="dateMov"
          popover-align="center"
          :disabled-dates="{ weekdays: weekdaysArray }"
          is-range
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  props: {
    typeCalendar: "",
    weekdaysArray: {
      type: Array,
      default: () => [],
    },
    labelCalendar: "",
  },
  data() {
    return {
      dateMov: "",
      dateModelMov: {
        startDate: "",
        endDate: "",
      },
    };
  },
  mounted() {},
  methods: {
    reloadComponent(type, message) {},
    modalDateRangeClean() {
      this.dateMov = "";
      this.dateModelMov.startDate = "";
      this.dateModelMov.endDate = "";
    },
  },
  computed: {
    ...mapState("locales", ["lang"]),
    modalDateRangeCalendar() {
      if (
        this.dateMov == "" ||
        this.dateMov == null ||
        this.dateMov == undefined
      ) {
        return "";
      }

      this.dateModelMov.startDate = moment(this.dateMov.start).format(
        "YYYY-MM-DD"
      );
      this.dateModelMov.endDate = moment(this.dateMov.end).format("YYYY-MM-DD");

      this.$emit("dateChange", this.dateModelMov);

      return `${moment(this.dateMov.start).format("YYYY-MM-DD")} al ${moment(
        this.dateMov.end
      ).format("YYYY-MM-DD")}`;
    },
    modalDateSingleCalendar() {
      if (
        this.dateMov == "" ||
        this.dateMov == null ||
        this.dateMov == undefined
      ) {
        return "";
      }

      this.dateModelMov.startDate = moment(this.dateMov).format("YYYY-MM-DD");
      //this.dateModelMov.endDate = moment(this.dateMov.end).format('YYYY-MM-DD');

      this.$emit("dateChange", this.dateModelMov);

      return `${moment(this.dateMov).format("YYYY-MM-DD")}`;
    },
    modalDateRangeCalendarValid() {
      if (
        this.dateMov == "" ||
        this.dateMov == null ||
        this.dateMov == undefined
      ) {
        return false;
      }
      //this.filter();
      return true;
    },
  },
};
</script>
