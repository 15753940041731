<template>
<div class="row container-center">
    <div class="col-md-6 col-sm-12 col-lg-6">
        <v-select v-model="select" outlined :items="answersOption" item-text="answer" item-value="idAnswerQuestion" 
        label="Selecciona una opción" @input="saveAnswers($event)"></v-select>
    </div>
</div>
</template>

<script>
export default {
    props: {
        answersQuestion: {
            required: true,
            default: 0,
        },
        answersOption: {
            required: true,
            default: [],
        },
        saveAnswers: Function
    },
    data() {
        return {
            select : {}
        }
    }
}
</script>

<style scoped>
.container-center {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 10px;
}
</style>
