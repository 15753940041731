<template>
<div class="row container-center">
    <div class="col-md-5 col-sm-12 col-lg-5">
        <v-text-field  @input="saveAnswers" outlined label="Escriba su respuesta:" :rules="rules" hide-details="auto"></v-text-field>
    </div>
</div>
</template>

<script>
export default {
    data: () => ({
        rules: [
            value => !!value || 'Debé escribir una respuesta.',
            value => (value && value.length >= 2) || 'Mínimo 2 caracteres',
        ],
    }),
    props: {
        saveAnswers: Function
    }
}
</script>

<style scoped>
.container-center {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 10px;
}
</style>
