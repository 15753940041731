<template>
  <div>
    <div class="card border-none">
      <div class="card-body">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="actionForm">
            <div class="row">
              <div class="col-12">
                <h6>
                  <v-icon class="pb-1" v-text="'mdi-numeric-1-box-outline'" />
                  Información del personal
                </h6>
              </div>
              <div class="col-12">
                <validation-provider
                  v-slot="{ errors }"
                  name="doctor_"
                  rules="required"
                >
                  <v-autocomplete
                    no-data-text="Sin resultados"
                    :disabled="sending"
                    :error-messages="errors"
                    :items="employeeList"
                    v-model="modelDispensing.employee.idEmployee"
                    label="Personal, departamento y puesto*"
                    required
                  />
                </validation-provider>
              </div>
            </div>
            <div class="row" v-show="modelDispensing.employee.idEmployee != ''">
              <div class="col-12">
                <hr />
                <h6>
                  <v-icon class="pb-1" v-text="'mdi-numeric-2-box-outline'" />
                  Información de la receta
                </h6>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="date_prescription"
                  rules="required"
                >
                  <v-text-field
                    @keypress="keypresDate"
                    :min="actionState == 3 ? null : minCurreDate"
                    :max="currentDate"
                    :disabled="
                      sending || modelDispensing.employee.idEmployee == ''
                    "
                    label="Fecha de la Receta"
                    type="date"
                    v-model="modelDispensing.datePrescription"
                    :error-messages="errors"
                    required
                  />
                </validation-provider>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Fecha de Dispensación"
                  rules="required"
                >
                  <v-text-field
                    @keypress="keypresDate"
                    :min="actionState == 3 ? null : minCurreDate"
                    :max="currentDate"
                    :disabled="
                      sending || modelDispensing.employee.idEmployee == ''
                    "
                    label="Fecha de Dispensación*"
                    type="date"
                    v-model="modelDispensing.dateDispensing"
                    :error-messages="errors"
                    required
                  />
                </validation-provider>
              </div>

              <div
                class="col-12 text-right"
                v-show="modelDispensing.employee.idEmployee != ''"
              >
                <button
                  class="btn btn-outline-success"
                  type="submit"
                  :disabled="sending || invalid"
                >
                  <i class="ti-plus mr-2"></i> {{ titleButton }}
                </button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </div>

    <DispensingModalConfirmComponent
      ref="modalConfirm"
      :status="modelDispensing.status"
      @confirm="confirmModalPrescription"
    />
  </div>
</template>

<script>
import moment from "moment";
import { UtilFront } from "@/core";
import { mapState, mapMutations } from "vuex";
import DispensingModalConfirmComponent from "@/components/dispensing//DispensingModalConfirmComponent";

import { MedicalPrescriptionRequest } from "@/core/request";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  props: {
    idUser: "",
    idBranchOffice: "",
    employeeList: {
      type: Array,
      required: true,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    DispensingModalConfirmComponent,
  },
  data() {
    return {
      /* Formulario */
      modelDispensing: {
        idOrder: "",
        idPrescription: "",
        doctor: {
          idDoctor: "",
        },
        employee: {
          idEmployee: "",
        },
        prescriptionKey: "",
        medicalRecord: "",
        curp: "",
        name: "",
        lastName: "",
        branchOfficeMedicalUnit: {
          idBranchOffice: "",
        },
        datePrescription: "",
        dateDispensing: moment().format("YYYY-MM-DD"),
        dateOfBirth: "",
        gender: 0,
        status: 0,
        type: 5,
        typeMedicalPrescription:2
      },
      genderList: [],
      currentDate: moment().format("YYYY-MM-DD"),
      minCurreDate: moment().subtract(4, 'months').format('YYYY-MM-DD'),

      /* carga */
      loading: null,
      sending: false,
    };
  },
  computed: {
    ...mapState("dispensing", ["idOrder", "idPrescription"]),
    ...mapState("auth", ["actionState"]),
    titleButton() {
      let title = "";
      if (this.modelDispensing.status === 0) {
        title = "Generar dispensación";
      } else if (this.modelDispensing.status === 1) {
        title = "Finalizar dispensación";
      } else if (this.modelDispensing.status === 2) {
        title = "Actualizar dispensación";
      }
      return title;
    },
    validationInformationPrescription() {
      return (
        this.modelDispensing.prescriptionKey != "" &&
        this.modelDispensing.medicalRecord != ""
      );
    },
  },
  methods: {
    ...mapMutations("dispensing", {
      updatePrescription: "UPDATE_ID_PRESCRIPTION",
      updateOrder: "UPDATE_ID_ORDER",
    }),
    confirmModalPrescription() {
      if (this.modelDispensing.idPrescription != "")
        this.finishMedicalPrescription();
      else this.addMedicalPrescription();
    },

    /* Informacion de la receta */
    actionForm() {
      this.$refs.modalConfirm.reloadComponent();
    },
    addMedicalPrescription() {
      if (!this.sending) {
        this.sending = true;
        //Muestro la pantalla de cargando
        this.loading = UtilFront.getLoadding();

        this.modelDispensing.userBranchOffice = {
          idBranchOffice: this.idBranchOffice,
          idUser: this.idUser,
        };

        MedicalPrescriptionRequest.addPrescription(this.modelDispensing)
          .then((response) => {
            const { success, message, data } = response.data.data;

            if (success) {
              this.modelDispensing = data;

              this.updateOrder(data.idOrder);
              this.updatePrescription(data.idPrescription);

              this.$toast.success("Receta generada correctamente.");
            } else {
              this.$emit("alert", "error", message);
            }
          })
          .catch((error) => {
            this.$toast.error(error);
            this.$emit("alert", "internal", "");
          })
          .finally(() => {
            //Elimino la pantalla de cargando
            this.loading.hide();
            this.sending = false;
            //Cierro el modal
            this.$refs.modalConfirm.reloadComponentClose();
          });
      } else return;
    },
    finishMedicalPrescription() {
      if (!this.sending) {
        this.numberDispensing >= 16 ? (this.numberDispensing = 0) : null; // Dado caso que el cotador no se reinicie.
        this.sending = true;

        //Muestro la pantalla de cargando
        this.loading = UtilFront.getLoadding();

        this.modelDispensing.userBranchOffice = {
          idBranchOffice: this.idBranchOffice,
          idUser: this.idUser,
        };
        this.modelDispensing.typeMedicalPrescription = 2;
        MedicalPrescriptionRequest.finishMedicalPrescription(
          this.modelDispensing
        )
          .then((response) => {
            const { success, message, data } = response.data.data;

            if (success) {
              this.$toast.success(
                this.modelDispensing.status == 1
                  ? "Receta finalizada correctamente."
                  : "Receta actualizada correctamente."
              );

              if (this.modelDispensing.status == 2) {
                this.$emit("changeAction", 1);
              } else {
                this.reloadComponentClean();
                this.updateOrder("");
              }
            } else {
              this.$toast.error(message);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            //Elimino la pantalla de cargando
            this.loading.hide();
            this.sending = false;
            //Cierro el modal
            this.$refs.modalConfirm.reloadComponentClose();
          });
      } else return;
    },
    reloadComponentForm(data) {
      this.modelDispensing = data;
    },
    reloadComponentClean() {
      this.modelDispensing = {
        idPrescription: "",
        idOrder: "",
        doctor: {
          idDoctor: "",
        },
        employee: {
          idEmployee: "",
        },
        prescriptionKey: "",
        medicalRecord: "",
        curp: "",
        name: "",
        lastName: "",
        branchOfficeMedicalUnit: {
          idBranchOffice: "",
        },
        datePrescription: "",
        dateDispensing: moment().format("YYYY-MM-DD"),
        dateOfBirth: "",
        gender: 0,
        status: 0,
        type: 5,
        typeMedicalPrescription:2
      };

      this.$refs.observer.reset();
    },

    keypresDate(event) {
      event.preventDefault();
    },
  },
};
</script>
