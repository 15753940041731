<template>
  <v-autocomplete
    :disabled="isDisabled"
    v-model="idProductModel"
    :items="productList"
    @change="productChange"
    :label="productLabel"
  >
    <template slot="item" slot-scope="data">
      <div class="pt-5">
        {{ data.item.text }}
      </div>
    </template>

    <template slot="selection" slot-scope="data">
      <div class="pt-5">
        {{ data.item.text }}
      </div>
    </template>

    <template v-slot:append>
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="#05AF12"
      ></v-progress-circular>
    </template>
  </v-autocomplete>
</template>

<script>
import { ProductResquest } from "@/core/request";
import { mapState, mapActions } from "vuex";
export default {
  props: {
    productLabel: "",
    idProduct: "",
  },
  data() {
    return {
      productList: [],
      isDisabled: false,
      loading: false,
      idProductModel: this.idProduct,
    };
  },
  computed: {
    ...mapState("remission", ["listProduct"]),
  },
  methods: {
    ...mapActions({ getListProducts: "remission/getListProducts" }),
    productChange() {
      this.$emit("productChange", this.idProductModel);
    },
    reloadComponentDisabled(isDisabled) {
      this.isDisabled = isDisabled;
    },
    reloadComponentClean() {
      this.idProductModel = "";
    },
    reloadComponent(idProduct) {
      this.idProductModel = idProduct;
    },
  },
  async created() {
    //Mustro cargar lista
    /* Lista GET_PRODUCTS: Produtos */
    await this.getListProducts();
    this.productList = this.listProduct.productFormList;
    this.loading = false;
  },
};
</script>
