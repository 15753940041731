<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :items="dataList"
      :search="search"
      height="auto"
      fixed-header
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      virtual-rows
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
    >
      <template v-slot:top>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <v-text-field
              v-model="search"
              :label="$t('table.search')"
            ></v-text-field>
          </div>
        </div>
      </template>

      <template
        v-slot:[`item.validate`]="{ item }"
        v-if="withPermissionName(permissions.USER_UPDATE_STATUS)"
      >
        <v-switch
          color="success"
          class="hand mt-5"
          dense
          @click="validate(item)"
          inset
          v-model="item.validate"
        />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top v-if="isDelete">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :disabled="sending"
              small
              class="mr-2"
              @click="deleteRecord(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $t("table.delete") }}</span>
        </v-tooltip>

        <!--<v-tooltip top v-if="isDetail" >
                          <template v-slot:activator="{ on, attrs }">
                          <span class="hand mr-2 " @click="detail(item)" v-bind="attrs" v-on="on">
                              <i class="feather icon-eye" ></i>
                          </span>
                          </template>
                        <span>Detalle</span>
                    </v-tooltip>-->

        <!-- <v-icon small class="mr-2" color="#E2E2E2" v-if="isUpdate && item.status == 3">
                      mdi mdi-square-edit-outline
                    </v-icon>-->

        <v-tooltip top v-if="isUpdate && item.status != 3">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2 pb-1"
              @click="updateRecord(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi mdi-square-edit-outline
            </v-icon>
          </template>
          <span>{{ $t("table.edit") }}</span>
        </v-tooltip>

        <v-tooltip top v-if="isPdf">
          <template v-slot:activator="{ on, attrs }">
            <span
              class="text-fmedical-red hand"
              @click="downloadPDF(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="fa fa-file-pdf-o mr-2"></i>
            </span>
          </template>
          <span>{{ $t("table.download_PDF") }}</span>
        </v-tooltip>

        <span v-if="isDetail && !item.validate">
          <i class="fa fa-files-o" style="color: #e2e2e2"></i>
        </span>

        <v-tooltip top v-if="isDetail && item.validate">
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand"
              @click="scannerFile(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="fa fa-files-o"></i>
            </span>
          </template>
          <span>Receta Escaneada</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <div class="col-12" v-if="isButton">
      <div class="invoice-footer-btn">
        <button
          class="btn btn-outline-secondary py-1 no-print"
          @click="print()"
        >
          <i class="feather icon-printer mr-2"></i> {{ $t("table.print") }}
        </button>
        <!--<button class="btn btn-success py-1 ml-3 no-print" @click="excelTable"><i class="fa fa-file-excel-o mr-2"></i> Excel</button>-->

        <!--<button class="btn btn-success py-1 ml-3 no-print" @click="excelTable"><i class="fa fa-filter mr-2"></i> Filtros</button>-->

        <b-dropdown
          size="sm"
          dropup
          text="Success"
          variant="success"
          class="ml-3 no-print"
        >
          <template #button-content>
            <i class="fa fa-file-excel-o mr-2"></i>{{ $t("table.excel") }}
          </template>
          <b-dropdown-item @click="excelTable">{{
            $t("dispensing.pre_dispensed")
          }}</b-dropdown-item>
          <b-dropdown-item @click="medicalPrescriptionDialog = true" href="#">{{
            $t("dispensing.text_pre_dispensedByDate")
          }}</b-dropdown-item>
        </b-dropdown>
        <!--<b-dropdown-item href="#">Todas las sucursales por fecha</b-dropdown-item>-->
      </div>
    </div>
    <div class="col-12" v-if="medicalPrescriptionDialog">
      <v-dialog v-model="medicalPrescriptionDialog" max-width="350" persistent>
        <v-card>
          <v-card-title class="headline">
            <small style="font-size: 100%">{{
              $t("dispensing.text_pre_by_date")
            }}</small>
          </v-card-title>
          <v-container>
            <!--<v-switch  v-model="allBranchOfficePrescripction" class="pl-5 pt-2" label="Todas las sucursales"></v-switch>-->
            <form @submit.prevent="excelMedicalPrescription()">
              <div class="col-md-12 text-center">
                <DateByRangeComponent
                  ref="DateByRangeComponent"
                  :typeCalendar="3"
                  v-on:dateChange="dateChange"
                />
              </div>
              <!--<v-switch  v-model="isFormat" class="pl-5 pt-2" label="Documento con formato de firma"></v-switch>-->
              <div class="col-md-12 text-center">
                <button class="btn btn-outline-success mr-4 mt-4" type="submit">
                  <i class="ti-reload mr-1"></i> {{ $t("global.generate") }}
                </button>
                <button
                  type="button"
                  class="btn btn-outline-danger mt-4"
                  @click="clearDateAndCloseDialog(2)"
                >
                  <i class="feather icon-x mr-1"></i> {{ $t("global.cancel") }}
                </button>
              </div>
            </form>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
  
  <script>
import { UtilFront, PermissionConstants } from "@/core";
import DateByRangeComponent from "@/common/calendar/DateByRangeComponent.vue";
import ModalDeleteProductComponent from "@/common/modal/ModalDeleteProductComponent";
import { ExcelRequest } from "@/core/request";

export default {
  props: {
    headers: {},
    labelSearch: "",
    isDelete: false,
    isUpdate: false,
    isDetail: false,
    isButton: false,
    isPdf: false,
    allIdBranchOffice: 0,
  },
  data() {
    return {
      search: "",
      sending: false,
      dataList: [],
      permissions: PermissionConstants,
      medicalPrescriptionDialog: false,
      dateModelMov: { startDate: "", endDate: "" },
    };
  },
  components: {
    DateByRangeComponent,
    ModalDeleteProductComponent,
  },
  methods: {
    /* General */
    excelTable() {
      this.$emit("excelTable");
    },
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    reloadComponent(dataList) {
      this.dataList = dataList;
    },
    deleteRecord(item) {
      this.$emit("deleteRecord", item);
    },
    updateRecord(item) {
      this.$emit("updateRecord", item);
    },
    downloadPDF(item) {
      this.$emit("downloadPDF", item);
    },
    scannerFile(item) {
      this.$emit("scannerFile", item);
    },
    validate(item) {
      this.$emit("validate", item);
    },
    detail(item) {
      this.$emit("detail", item);
    },
    reloadComponentSending(sending) {
      this.sending = sending;
    },
    reloadComponentDeleteItem(item) {
      this.dataList.splice(item, 1);
    },
    itemRowBackground: function (item, index) {
      return item.status == 3 ? "style-2" : "";
    },
    dateChange(dateShipment) {
      this.dateShipment = dateShipment;
      this.dateModelMov = dateShipment;
    },
    excelMedicalPrescription() {
      if (
        this.dateModelMov.startDate != "" &&
        this.dateModelMov.endDate != ""
      ) {
        //Muestro la pantalla de carga
        this.loading = UtilFront.getLoadding();

        ExcelRequest.reportMedicalPrescriptionByDate({
          idBranchOffice: this.allIdBranchOffice,
          level: this.allBranchOfficePrescripction ? 1 : 2,
          //idUser: 0,
          startDate: this.dateModelMov.startDate,
          endDate: this.dateModelMov.endDate,
          format: this.isFormat,
        })
          .then((response) => {
            console.log(response);
            let { success, message, data } = response.data.data;

            if (success) {
              ExcelRequest.downloadBase64(data, message);
              this.$toast.success("Generando documento Xlsx");
            } else {
              this.$toast.error(message);
            }
          })
          .finally(() => {
            this.loading.hide();
          });
      }
    },
    clearDateAndCloseDialog(dialog) {
      this.dateModelMov.startDate = "";
      this.dateModelMov.endDate = "";
      this.$refs.DateByRangeComponent.modalDateRangeClean();
      if (dialog == 1) {
        this.dialogDateRange = false;
      } else if (dialog == 2) {
        this.medicalPrescriptionDialog = false;
      }
    },
  },
  created() {},
};
</script>
  
  <style scoped>
.style-2 {
  background-color: rgb(254, 187, 187);
}
</style>
  
  