<template>
  <div>
    <DispensingTableProducts
      ref="tableOrderPrescription"
      :headers="orderProductsHeaders"
      :isDelete="true"
      @deleteRecord="modalDelete"
    />

    <div class="card">
      <div class="card-body">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form
            @submit.prevent="addStockInOrder"
            @keydown.enter.prevent="disableEnterKey"
          >
            <div class="row">
              <div class="col-md-12 col-xl-3">
                <validation-provider
                  name="source_of_financing"
                  rules="required"
                >
                  <v-autocomplete
                    :no-data-text="$t('label.no_results')"
                    :disabled="sending"
                    @change="getProductsByFiscalFound"
                    :items="fiscalFoundList"
                    v-model="idFiscalFound"
                    :label="$t('label.source_of_financing')"
                    required
                  >
                  </v-autocomplete>
                </validation-provider>
              </div>
              <div class="col-md-12 col-xl-9">
                <validation-provider name="product" rules="required">
                  <v-autocomplete
                    :no-data-text="$t('label.no_results')"
                    :disabled="sending"
                    auto-select-first
                    :items="productsList"
                    v-model="idProduct"
                    :label="$t('label.product_')"
                    @change="getLotsByProduct"
                  >
                    <template slot="item" slot-scope="data">
                      <div class="pt-5">
                        {{ data.item.text }}
                      </div>
                    </template>

                    <template slot="selection" slot-scope="data">
                      <div class="pt-5">
                        {{ data.item.text }}
                      </div>
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-12 col-xl-3">
                <validation-provider name="lot" rules="required">
                  <v-autocomplete
                    :no-data-text="$t('label.no_results')"
                    :disabled="sending"
                    auto-select-first
                    :items="lotList"
                    v-model="idStock"
                    :label="$t('label.lot')"
                    required
                  >
                  </v-autocomplete>
                </validation-provider>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-xl-3">
                <validation-provider
                  v-slot="{ errors }"
                  name="quantity"
                  rules="required|numeric"
                >
                  <v-text-field
                    :counter="5"
                    :maxlength="5"
                    :disabled="sending"
                    v-model="quantity"
                    :error-messages="errors"
                    :label="$t('label.quantity_dispensed')"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-xl-3">
                <validation-provider
                  v-slot="{ errors }"
                  name="amount_prescribed"
                  rules="required|numeric"
                >
                  <v-text-field
                    :counter="5"
                    :maxlength="5"
                    :disabled="sending"
                    v-model="prescription"
                    :error-messages="errors"
                    :label="$t('label.amount_prescribed')"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-xl-3 pt-7 text-left">
                <button
                  class="btn btn-outline-success"
                  type="submit"
                  :disabled="invalid || sending"
                >
                  <i class="ti-plus mr-3"></i> {{ $t("global.save_remission") }}
                </button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </div>

    <ModalDeleteProductComponent
      ref="modalDeleteProduct"
      v-on:deleteRecord="deleteRecord"
    />
  </div>
</template>
<script type="js">
import { UtilFront } from "@/core";
import { mapState } from "vuex";
import {
  ProductResquest,
  LotRequest,
  StockRequest,
  OrderStockRequest,
} from "@/core/request";
import DispensingTableProducts from "@/components/dispensing/DispensingTableProducts";
import ModalDeleteProductComponent from "@/common/modal/ModalDeleteProductComponent";
import { ORDER_PRODUCTS_PRESCRIPTION } from "@/core/DataTableHeadersLocale";

import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  props: {
    idUser: "",
    idBranchOffice: "",
    fiscalFoundList: {
      type: Array,
      required: true,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    DispensingTableProducts,
    ModalDeleteProductComponent,
  },
  data() {
    return {
      /* cabecera */
      orderProductsHeaders: ORDER_PRODUCTS_PRESCRIPTION(),

      productList: [],
      lotList: [],

      idFiscalFound: "",
      idProduct: "",
      idStock: "",
      quantity: "",
      prescription: "",
      type: 8,

      productsList: [],

      /* carga */
      loading: null,
      sending: false,
    };
  },
  computed: {
    ...mapState("dispensing", ["idOrder", "idPrescription", "isUpdate"]),
    titleButton() {
      return this.orderUpdate ? "Actualizar Receta" : "Guardar Dispensación";
    },
  },
  methods: {
    disableEnterKey(event) {
      if (event.repeat || this.isSubmitting) event.preventDefault();
    },
    clean(level) {
      if (level == 0) {
        //this.idOrder = '';
      }

      if (level <= 1) {
        this.idFiscalFound = "";
      }

      if (level <= 2) {
        //Se encarga de limpiar la lista de productos el item seleccionado
        this.productsList = [];
        this.idProduct = "";
      }

      if (level <= 3) {
        //Se encarga de limpiar la lista de lotes y el item seleccionado
        this.lotList = [];
        this.idStock = "";
      }

      if (level <= 4) {
        //Se encarga de limpiar los campos de cantidad
        this.quantity = "";
        this.prescription = "";

        this.$refs.observer.reset();
      }
    },
    reloadComponentClean() {
      this.clean(0);
      this.idFiscalFound = "";
    },
    modalDelete(item) {
      this.$refs.modalDeleteProduct.reloadComponent(item);
    },
    //Recupero los productos por fuente de financiamiento, sucursal y motivo de merma
    getProductsByFiscalFound() {
      this.clean(2);
      //this.loadingProducts = true;
      if (this.idFiscalFound != "") {
        ProductResquest.findAllProductosByStockByType({
          shipmentsShippingReason: this.type,
          fiscalFundId: this.idFiscalFound,
          typeModule: 3,
          typeWarehouse: 1,
          idUser: this.idUser,
          idBranchOffice: this.idBranchOffice,
        }).then((response) => {
          const { success, message, data } = response.data.data;

          if (success) {
            this.productsList = data;
          }
        });
      }
    },
    getLotsByProduct() {
      this.clean(3);

      LotRequest.findAllLotsByType({
        shipmentsShippingReason: this.type,
        fiscalFundId: this.idFiscalFound,
        typeModule: 3,
        typeWarehouse: 1,
        productId: this.idProduct,
        idUser: this.idUser,
        idBranchOffice: this.idBranchOffice,
      }).then((response) => {
        const { success, message, data } = response.data.data;

        if (success) {
          this.lotList = data;
        }
      });
    },
    getLots() {
      this.clean(4);
    },
    //Funcion que se encarga de agregar los productos a la orden
    addStockInOrder() {
      if (!this.sending) {
        this.sending = true;

        if (this.quantity != "" && this.prescription != "") {
          //Muestro la pantalla de cargando
          this.loading = UtilFront.getLoadding();

          StockRequest.updateStockPrescription({
            idPrescription: this.idPrescription,
            orderStock: {
              orderId: this.idOrder,
              stockId: this.idStock,
              quantity: this.quantity,
              prescription: this.prescription,
            },
            userBranchOffice: {
              idUser: this.idUser,
              idBranchOffice: this.idBranchOffice,
            },
          })
            .then((response) => {
              let { data } = response.data;
              let { success, message } = data;

              if (success) {
                this.$toast.success("Producto agregado a la receta.");

                this.orderDetail(this.idOrder);

                //this.clean(3);
                this.getProductsByFiscalFound();
              } else {
                this.$emit("alert", "error", message);
              }
            })
            .catch((error) => {
              this.$toast.error("Ocurrio un error interno");
            })
            .finally(() => {
              this.sending = false;
              //Elimino la pantalla de cargando
              this.loading.hide();
            });
        } else {
          //this.$refs.alertDecrease.reloadComponent( "error", "Es necesario ingresar la cantidad" );
          this.$toast.error("Es necesario ingresar la cantidad");
        }
      }
    },
    orderDetail(idOrder) {
      OrderStockRequest.orderStockDetail({
        idOrder: idOrder,
        status: [1],
      })
        .then((response) => {
          const { status, data } = response;
          this.$refs.tableOrderPrescription.reloadComponent(data);
        })
        .catch((error) => {
          //this.$refs.alertStartingOrder.reloadComponent("internal","");
        })
        .finally(() => {
          //Elimino la pantalla de cargando
          //this.loading.hide();
        });
    },
    deleteRecord(item) {
      if (!this.sending) {
        this.sending = true;

        //Mustro la pantalla de cargando
        this.loading = UtilFront.getLoadding();

        OrderStockRequest.disableOrderStock({
          idOrderStock: item.idOrderStock,
          userId: this.idUser,
          branchOfficeId: this.idBranchOffice,
        })
          .then((response) => {
            let { status, data } = response;
            if (status == 200) {
              //Cierro el modal
              this.$refs.modalDeleteProduct.reloadComponentClose();
              this.orderDetail(this.idOrder);
              this.getProductsByFiscalFound();
              this.$toast.success("Producto eliminado correctamente.");
            } else if (status == 202) {
              //this.$toast.error("No fue posible eliminar el producto.");
              //this.$refs.alertOrder.reloadComponent("error", data);
              this.alert("error", response.data);
            }
          })
          .catch((error) => {
            this.$toast.error("No fue posible eliminar el producto.");
            //this.$refs.alertOrder.reloadComponent("internal", "");
          })
          .finally(() => {
            //Habilito los botones finalizo el envio
            this.sending = false;
            //Elimino la pantalla de cargando
            this.loading.hide();
          });
      } else {
        return;
      }
    },
  },
  watch: {
    idOrder: function (val) {
      if (val === "") {
        this.$refs.tableOrderPrescription.reloadComponent([]);
        this.clean(0);
      } else if (val != "" && this.isUpdate) this.orderDetail(val);
    },
    "$i18n.locale": function () {
      this.orderProductsHeaders = ORDER_PRODUCTS_PRESCRIPTION();
    },
  },
};
</script>
