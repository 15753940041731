import { render, staticRenderFns } from "./AddDoctorComponent.vue?vue&type=template&id=005a5516&"
import script from "./AddDoctorComponent.vue?vue&type=script&lang=js&"
export * from "./AddDoctorComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports