<template>
  <div>
    <div class="card border-none">
      <div class="card-body pt-0">
        <DispensingValidationComponent
          ref="prescriptionKey"
          :modelDispensing="modelDispensing"
          :loadingFolioValidation="loadingFolioValidation"
          :folioValidation="folioValidation"
          :validatePrescriptionIcon="validatePrescriptionIcon"
          :typeMedicalPrescription="typeMedicalPrescription"
          :validatePrescriptionKey="validatePrescriptionKey"
        />

        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="actionForm">
            <div class="row" v-show="formValidate">
              <div class="col-12">
                <hr />
                <h6>
                  <v-icon class="pb-1" v-text="'mdi-numeric-2-box-outline'" />
                  {{ $t("dispensing.text_pre_info") }}
                </h6>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                <h6>¿Farmacia 24 horas?</h6>
                <div class="row">
                  <div class="col-2">
                    <v-switch
                      v-model="modelDispensing.fullTime"
                      :disabled="sending"
                      :label="
                        modelDispensing.fullTime
                          ? $t('dispensing.yes')
                          : $t('dispensing.no')
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <validation-provider
                  v-slot="{ errors }"
                  name="doctor_"
                  rules="required"
                >
                  <v-autocomplete
                    :no-data-text="$t('label.no_results')"
                    :items="doctorList"
                    :disabled="sending"
                    auto-select-first
                    :error-messages="errors"
                    v-model="modelDispensing.doctor.idDoctor"
                    :label="$t('label.doctor')"
                    required
                  >
                    <template v-slot:append-outer>
                      <v-icon
                        color="info"
                        @click="dialogDoctor = true"
                        v-text="'mdi-plus-circle-outline'"
                      ></v-icon>
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </div>

              <div class="col-12">
                <validation-provider
                  v-slot="{ errors }"
                  name="number_file"
                  rules="required|max:20"
                >
                  <v-text-field
                    oninput="this.value = this.value.toUpperCase()"
                    :disabled="sending"
                    :counter="20"
                    :maxlength="20"
                    v-model="modelDispensing.medicalRecord"
                    :error-messages="errors"
                    :label="$t('label.number_file')"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="date_prescription"
                  rules="required"
                >
                  <v-text-field
                    @keypress="keypresDate"
                    :min="actionState == 3 ? null : minCurreDate"
                    :max="currentDate"
                    :disabled="sending"
                    :label="$t('label.date_prescription')"
                    type="date"
                    v-model="modelDispensing.datePrescription"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="date_dispensing"
                  rules="required"
                >
                  <v-text-field
                    @keypress="keypresDate"
                    :min="actionState == 3 ? null : minCurreDate"
                    :max="currentDate"
                    :disabled="sending"
                    :label="$t('label.date_dispensing')"
                    type="date"
                    v-model="modelDispensing.dateDispensing"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                <h6>{{ $t("dispensing.text_medical_unit") }}</h6>
                <div class="row">
                  <div class="col-2">
                    <v-switch
                      v-model="isMedicalUnit"
                      :disabled="sending"
                      :label="
                        isMedicalUnit
                          ? $t('dispensing.yes')
                          : $t('dispensing.no')
                      "
                    />
                  </div>
                </div>
              </div>

              <div
                class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9"
                v-show="!isMedicalUnit"
              >
                <v-autocomplete
                  v-model="
                    modelDispensing.branchOfficeMedicalUnit.idBranchOffice
                  "
                  :items="branchOfficeList"
                  :disabled="sending"
                  :label="$t('label.unit_medical')"
                />
              </div>

              <div class="col-12">
                <hr />
                <h6>
                  <v-icon class="pb-1" v-text="'mdi-numeric-3-box-outline'" />
                  {{ $t("dispensing.info_beneficiary_text") }}
                </h6>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                  rules="required|max:50|alpha_spaces"
                >
                  <v-text-field
                    :disabled="sending"
                    :counter="50"
                    oninput="this.value = this.value.toUpperCase()"
                    :maxlength="50"
                    v-model="modelDispensing.name"
                    :error-messages="errors"
                    :label="$t('label.name')"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                <validation-provider
                  v-slot="{ errors }"
                  name="lastname"
                  rules="required|max:100|alpha_spaces"
                >
                  <v-text-field
                    :disabled="sending"
                    :counter="100"
                    oninput="this.value = this.value.toUpperCase()"
                    :maxlength="100"
                    v-model="modelDispensing.lastName"
                    :error-messages="errors"
                    :label="$t('label.lastname')"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="curp"
                  rules="required|alpha_num|min:18|max:18"
                >
                  <v-text-field
                    :disabled="sending"
                    :counter="18"
                    oninput="this.value = this.value.toUpperCase()"
                    :maxlength="18"
                    v-model="modelDispensing.curp"
                    :error-messages="errors"
                    :label="$t('label.curp')"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <validation-provider
                  v-slot="{ errors }"
                  name="birth_date"
                  rules="required"
                >
                  <v-text-field
                    @keypress="keypresDate"
                    :max="currentDate"
                    :disabled="sending"
                    :label="$t('label.birth_date')"
                    type="date"
                    v-model="modelDispensing.dateOfBirth"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <validation-provider
                  v-slot="{ errors }"
                  name="gender"
                  rules="required"
                >
                  <v-select
                    name="gender"
                    :error-messages="errors"
                    no-data-text="Sin resultados"
                    :items="genderList"
                    :disabled="sending"
                    v-model="modelDispensing.gender"
                    :label="$t('label.gender')"
                    required
                  >
                    <template v-slot:selection="data">
                      {{ $t(data.item.value) }}
                    </template>
                    <template v-slot:item="data">
                      {{ $t(data.item.value) }}
                    </template>
                  </v-select>
                </validation-provider>
              </div>
              <div class="col-12 text-right">
                <button
                  class="btn btn-outline-success"
                  type="submit"
                  :disabled="sending || invalid"
                >
                  <i class="ti-plus mr-2"></i> {{ titleButton }}
                </button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </div>

    <!-- Dispensacion orden de productos -->

    <v-row justify="center">
      <v-dialog v-model="dialogDoctor" max-width="1000" persistent>
        <v-card>
          <v-card-title class="headline">
            <small style="font-size: 100%">Agregar nuevo doctor</small>
          </v-card-title>
          <v-container>
            <AddDoctorComponent
              ref="formAddDoctor"
              :idUser="idUser"
              :idBranchOffice="idBranchOffice"
              :isModal="true"
              v-on:alert="alertDoctor"
              v-on:closeModalDoctor="closeModalDoctor"
            />
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>

    <DispensingModalConfirmComponent
      ref="modalConfirm"
      :status="modelDispensing.status"
      @confirm="confirmModalPrescription"
    />
  </div>
</template>

<script>
import moment from "moment";
import { UtilFront } from "@/core";
import { mapState, mapMutations } from "vuex";
import AddDoctorComponent from "@/components/doctor/AddDoctorComponent";
import DispensingModalConfirmComponent from "@/components/dispensing//DispensingModalConfirmComponent";
import { MedicalPrescriptionRequest } from "@/core/request";
import genderData from "@/assets/json/gender.json";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { maska } from "maska";
import DispensingValidationComponent from "@/components/dispensing/form/DispensingValidationComponent";

export default {
  props: {
    idUser: "",
    idBranchOffice: "",
    doctorList: {
      type: Array,
      required: true,
    },
    branchOfficeList: {
      type: Array,
      required: true,
    },
  },
  directives: { maska },
  components: {
    ValidationProvider,
    ValidationObserver,
    AddDoctorComponent,
    DispensingModalConfirmComponent,
    DispensingValidationComponent,
  },
  data() {
    return {
      /* Formulario */
      modelDispensing: {
        idOrder: "",
        idPrescription: "",
        doctor: {
          idDoctor: "",
        },
        prescriptionKey: "",
        medicalRecord: "",
        curp: "",
        name: "",
        lastName: "",
        branchOfficeMedicalUnit: {
          idBranchOffice: "",
        },
        datePrescription: "",
        dateDispensing: moment().format("YYYY-MM-DD"),
        dateOfBirth: "",
        gender: null,
        status: 0,
        type: 2,
        typeMedicalPrescription: 0,
        fullTime: false,
      },
      genderList: null,
      typeMedicalPrescription: { value: false },
      currentDate: moment().format("YYYY-MM-DD"),
      minCurreDate: moment()
        .subtract(4, "months")
        .format("YYYY-MM-DD"),
      folioValidation: "info",
      formValidate: false,

      validatePrescriptionIcon: "mdi-information-outline",
      validatePrescriptionColor: "",
      validatePrescriptionbackgroundColor: "",

      dialogDoctor: false,
      isMedicalUnit: true,

      /* carga */
      loadingFolioValidation: false,
      loading: null,
      sending: false,
      lengthKey: 0,
      length: "",
    };
  },
  computed: {
    ...mapState("dispensing", [
      "idOrder",
      "idPrescription",
      "surveyCount",
      "totalSurveys",
    ]),
    ...mapState("auth", ["actionState"]),
    titleButton() {
      let title = "";
      if (this.modelDispensing.status === 0) {
        title = this.$t("global.generate_dispensation");
      } else if (this.modelDispensing.status === 1) {
        title = this.$t("global.end_dispensation");
      } else if (this.modelDispensing.status === 2) {
        title = this.$t("global.update_dispensing");
      }
      return title;
    },
    validationInformationPrescription() {
      return (
        this.modelDispensing.prescriptionKey != "" &&
        this.modelDispensing.medicalRecord != ""
      );
    },
  },
  methods: {
    ...mapMutations("dispensing", {
      updateSurveyCount: "UPDATE_SURVEY_COUNT",
      updatePrescription: "UPDATE_ID_PRESCRIPTION",
      updateOrder: "UPDATE_ID_ORDER",
    }),
    /* Doctor */
    alertDoctor(success, message, newDoctor) {
      //emitir alerta
      if (success == "success") {
        //Guardo nuevo objeto a la lista de doctores
        this.doctorList.push(newDoctor);
        this.modelDispensing.doctor.idDoctor = newDoctor.value;

        //Limpio los campos del doctor
        this.$refs.formAddDoctor.reloadComponentClean();

        //Cierro el modal de doctor
        this.dialogDoctor = false;
      } else {
        this.$emit("alert", success, message);
        //this.alert( success, message );
      }
    },
    closeModalDoctor() {
      this.dialogDoctor = false;
    },
    async confirmModalPrescription() {
      if (!this.sending) {
        this.sending = true;
        this.loading = UtilFront.getLoadding();

        const valid = await this.validatePrescriptionKey();
        if (valid) {
          this.modelDispensing.userBranchOffice = {
            idBranchOffice: this.idBranchOffice,
            idUser: this.idUser,
          };
          this.modelDispensing.typeMedicalPrescription = this
            .typeMedicalPrescription.value
            ? 3
            : 1;

          if (this.modelDispensing.idPrescription == "")
            await this.addMedicalPrescription();
          else await this.finishMedicalPrescription();
        }

        this.loading.hide();
        this.sending = false;
        this.$refs.modalConfirm.reloadComponentClose();
      }
    },

    /* Informacion de la receta */
    actionForm() {
      this.$refs.modalConfirm.reloadComponent();
    },
    async validatePrescriptionKey() {
      this.sending = true;
      let valid = await this.$refs.prescriptionKey.validateForm();
      if (valid) {
        this.loadingFolioValidation = true;
        await MedicalPrescriptionRequest.validatePrescriptionKey({
          idPrescription: this.modelDispensing.idPrescription,
          prescriptionKey: this.modelDispensing.prescriptionKey,
          typeMedicalPrescription: this.typeMedicalPrescription.value ? 3 : 1,
        })
          .then((response) => {
            const { success, message } = response.data.data;
            if (success) {
              this.folioValidation = "success";
              this.formValidate = true;
            } else {
              this.$emit("alert", "error", message);
              this.folioValidation = "error";
              valid = false;
            }
          })
          .catch((error) => {
            this.$toast.error(error);
          })
          .finally(() => {
            this.loadingFolioValidation = false;
          });
      } else {
        this.folioValidation = "error";
      }
      this.sending = false;
      return valid;
    },
    async addMedicalPrescription() {
      this.modelDispensing.typeMedicalPrescription = this
        .typeMedicalPrescription.value
        ? 3
        : 1;

      await MedicalPrescriptionRequest.addPrescription(this.modelDispensing)
        .then((response) => {
          const { success, message, data } = response.data.data;

          if (success) {
            this.modelDispensing = data;
            this.updateOrder(data.idOrder);
            this.updatePrescription(data.idPrescription);
            this.$toast.success("Receta generada correctamente.");
          } else {
            this.$emit("alert", "error", message);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
    async finishMedicalPrescription() {
      if (this.isMedicalUnit) {
        this.modelDispensing.branchOfficeMedicalUnit.idBranchOffice = "";
      }

      await MedicalPrescriptionRequest.finishMedicalPrescription(
        this.modelDispensing
      )
        .then((response) => {
          const { success, message, data } = response.data.data;

          if (success) {
            this.$toast.success(
              this.modelDispensing.status == 1
                ? "Receta finalizada correctamente."
                : "Receta actualizada correctamente."
            );

            if (this.modelDispensing.status == 2) {
              this.$emit("changeAction", 1);
            } else {
              this.reloadComponentClean();

              this.updateOrder("");
              this.updateSurveyCount(this.surveyCount + 1);

              //Elimino el id de la receta solo si ya se efectuaron las dispensaciones
              if (this.surveyCount != this.totalSurveys)
                this.updatePrescription("");
            }
          } else {
            this.$emit("alert", "error", message);
            this.$toast.error(error);
            //this.folioValidation = 'info';
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },

    reloadComponentDoctorList(list) {
      this.doctorList = list;
    },
    async reloadComponentForm(data) {
      //Cargo el formulario
      this.modelDispensing = data;
      if (
        data.typeMedicalPrescription === 1 ||
        data.typeMedicalPrescription === 2
      ) {
        this.typeMedicalPrescription.value = false;
      } else if (data.typeMedicalPrescription == 3) {
        this.typeMedicalPrescription.value = true;
      }
      this.folioValidation = "info";
      this.formValidate = true;

      //Habilito la opcion de unidad medica
      this.isMedicalUnit =
        data.branchOfficeMedicalUnit.idBranchOffice == "" ||
        data.branchOfficeMedicalUnit.idBranchOffice == undefined;

      this.$refs.prescriptionKey.reloadComponent(data);
    },
    reloadComponentClean() {
      this.modelDispensing = {
        idPrescription: "",
        idOrder: "",
        doctor: {
          idDoctor: "",
        },
        prescriptionKey: "",
        medicalRecord: "",
        curp: "",
        name: "",
        lastName: "",
        branchOfficeMedicalUnit: {
          idBranchOffice: "",
        },
        datePrescription: "",
        dateDispensing: moment().format("YYYY-MM-DD"),
        dateOfBirth: "",
        gender: null,
        status: 0,
        type: 2,
        typeMedicalPrescription: 0,
      };
      this.typeMedicalPrescription.value = false;
      (this.lengthKey = 0), (this.tab = 0);
      this.isMedicalUnit = false;
      this.$refs.observer.reset();
      this.folioValidation = "info";
      this.formValidate = false;

      //Habilito la opcion de unidad medica
      this.isMedicalUnit =
        this.modelDispensing.branchOfficeMedicalUnit.idBranchOffice == "";
      this.$refs.prescriptionKey.clearInput();
    },

    keypresDate(event) {
      event.preventDefault();
    },

    clearInput() {
      this.modelDispensing.prescriptionKey = "";
    },
  },
  watch: {
    folioValidation: function(val) {
      switch (val) {
        case "info":
          this.validatePrescriptionIcon = "mdi-information-outline";
          this.validatePrescriptionColor = "";
          this.validatePrescriptionbackgroundColor = "";
          break;
        case "success":
          this.validatePrescriptionIcon = "mdi-checkbox-marked-circle-outline";
          this.validatePrescriptionColor = "#048e61";
          this.validatePrescriptionbackgroundColor = "#dfede7";
          break;
        case "error":
          this.validatePrescriptionIcon = "mdi-close-circle-outline";
          this.validatePrescriptionColor = "#FF5B5B";
          this.validatePrescriptionbackgroundColor = "#FFD1D1";
          break;
        default:
      }
    },
    "modelDispensing.prescriptionKey": function(e) {
      const str = e;
      if (e.length > 1) {
        if (str.substring(0, 2) == 21) {
          this.lengthKey = 8;
          this.length = "########";
        } else if (str.substring(0, 2) == 22) {
          this.lengthKey = 10;
          this.length = "##########";
        }
      }
    },
  },

  created() {
    this.genderList = genderData;
  },
};
</script>
