<template>
<div class="row container-center">
    <div v-for="answers of answersOption" :key="answers.idAnswerQuestion" class="col-md-4 col-sm-4 col-lg-2">
        <div v-if="answers.answer === 'Nada(Insatisfecho)'">
            <div class="card">
                <button v-bind:class="[
                    answersQuestion === answers.idAnswerQuestion
                     ? 'btn btn-warning check-selected orange'
                      : 'btn btn-outline-warning check orange_hover',
                    ,
                  ]" @click="saveAnswers(answers.idAnswerQuestion)">
                    <img src="@/assets/images/quiz/Cara_Naranja.svg" class="img-resposive" />
                    <div class="col-md-12 col-sm-12" v-bind:class="[
                      answersQuestion === answers.idAnswerQuestion ? 'check-img-select' : 'check-img',
                      ,
                    ]">
                        <span v-bind:class="[
                        answersQuestion === answers.idAnswerQuestion
                          ? 'mdi mdi-checkbox-blank-circle'
                          : 'mdi mdi-checkbox-blank-circle-outline check',
                        ,
                      ]"></span>
                    </div>
                    <div class="col-md-12 col-sm-12" v-bind:class="[
                      answersQuestion === answers.idAnswerQuestion
                        ? 'title-face-select'
                        : 'title-face',
                      ,
                    ]">
                        <p> {{answers.answer}} </p>
                    </div>
                </button>
            </div>
        </div>
        <div v-if="answers.answer === 'Regular(más o menos satisfecho)'">
            <div class="card ">
                <button v-bind:class="[
                    answersQuestion === answers.idAnswerQuestion
                      ? 'btn btn-warning check-selected warning'
                      : 'btn btn-outline-warning check warning_hover',
                    ,
                  ]" @click="saveAnswers(answers.idAnswerQuestion)">
                    <img src="@/assets/images/quiz/Cara_Amarilla.svg" class="img-resposive" />
                    <div class="col-md-12 col-sm-12" v-bind:class="[
                      answersQuestion === answers.idAnswerQuestion ? 'check-img-select' : 'check-img',
                      ,
                    ]">
                        <span v-bind:class="[
                        answersQuestion === answers.idAnswerQuestion
                          ? 'mdi mdi-checkbox-blank-circle'
                          : 'mdi mdi-checkbox-blank-circle-outline check',
                        ,
                      ]"></span>
                    </div>
                    <div class="col-md-12 col-sm-12" v-bind:class="[
                      answersQuestion === answers.idAnswerQuestion
                        ? 'title-face-select'
                        : 'title-face',
                      ,
                    ]">
                        <p> {{answers.answer}} </p>
                    </div>
                </button>
            </div>
        </div>
        <div v-if="answers.answer === 'Mucho(Satisfecho)'">
            <div class="card">
                <button v-bind:class="[
                    answersQuestion === answers.idAnswerQuestion
                      ? 'btn btn-success check-selected success'
                      : 'btn btn-outline-success check success_hover',
                    ,
                  ]" @click="saveAnswers(answers.idAnswerQuestion)">
                    <img src="@/assets/images/quiz/Cara_Verde.svg" class="img-resposive" />
                    <div class="col-md-12 col-sm-12" v-bind:class="[
                      answersQuestion === answers.idAnswerQuestion ? 'check-img-select' : 'check-img',
                      ,
                    ]">
                        <span v-bind:class="[
                        answersQuestion === answers.idAnswerQuestion
                          ? 'mdi mdi-checkbox-blank-circle'
                          : 'mdi mdi-checkbox-blank-circle-outline',
                        ,
                      ]"></span>
                    </div>
                    <div class="col-md-12 col-sm-12" v-bind:class="[
                      answersQuestion === answers.idAnswerQuestion
                        ? 'title-face-select'
                        : 'title-face',
                      ,
                    ]">
                        <p> {{answers.answer}} </p>
                    </div>
                </button>
            </div>
        </div>
        <div v-if="answers.answer === 'No'">
            <div class="card">
                <button v-bind:class="[
                    answersQuestion === answers.idAnswerQuestion
                      ? 'btn btn-warning check-selected orange'
                      : 'btn btn-outline-warning check orange_hover',
                    ,
                  ]" @click="saveAnswers(answers.idAnswerQuestion)">
                    <img src="@/assets/images/quiz/danger.png" class="img-resposive" />
                    <div class="col-md-12 col-sm-12" v-bind:class="[
                      answersQuestion === answers.idAnswerQuestion ? 'check-img-select' : 'check-img',
                      ,
                    ]">
                        <span v-bind:class="[
                        answersQuestion === answers.idAnswerQuestion
                          ? 'mdi mdi-checkbox-blank-circle'
                          : 'mdi mdi-checkbox-blank-circle-outline check',
                        ,
                      ]"></span>
                    </div>
                    <div class="col-md-12 col-sm-12" v-bind:class="[
                      answersQuestion === answers.idAnswerQuestion
                        ? 'title-face-select'
                        : 'title-face',
                      ,
                    ]">
                        <p> {{answers.answer}} </p>
                    </div>
                </button>
            </div>
        </div>
        <div v-if="answers.answer === 'Si'">
            <div class="card">
                <button v-bind:class="[
                    answersQuestion === answers.idAnswerQuestion
                      ? 'btn btn-success check-selected success'
                      : 'btn btn-outline-success check success_hover',
                    ,
                  ]" @click="saveAnswers(answers.idAnswerQuestion)">
                    <img src="@/assets/images/quiz/success.png" class="img-resposive" />
                    <div class="col-md-12 col-sm-12" v-bind:class="[
                      answersQuestion === answers.idAnswerQuestion ? 'check-img-select' : 'check-img',
                      ,
                    ]">
                        <span v-bind:class="[
                        answersQuestion === answers.idAnswerQuestion
                          ? 'mdi mdi-checkbox-blank-circle'
                          : 'mdi mdi-checkbox-blank-circle-outline',
                        ,
                      ]"></span>
                    </div>
                    <div class="col-md-12 col-sm-12" v-bind:class="[
                      answersQuestion === answers.idAnswerQuestion
                        ? 'title-face-select'
                        : 'title-face',
                      ,
                    ]">
                        <p> {{answers.answer}} </p>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        answersQuestion: {
            required: true,
            default: 0,
        },
        answersOption: {
            required: true,
            default: {},
        },
        saveAnswers: Function
    },
}
</script>

<style scoped>
.orange {
    background-color: #f4a261 !important;
    border-color: #f4a261 !important;
}

.orange_hover:hover  {
    background-color: #f4a261 !important;
    border-color: #f4a261 !important;
    
}

.warning {
    background-color: #ffce52 !important;
    border-color: #ffce52 !important;
}

.warning_hover:hover  {
    background-color: #ffce52 !important;
    border-color: #ffce52 !important;
    
}

.success {
    background-color: #95d5b2 !important;
    border-color: #95d5b2 !important;
}

.success_hover:hover {
    background-color: #95d5b2 !important;
    border-color: #95d5b2 !important;
}

.container-center {
    justify-content: center;
    align-items: center;
    display: flex;
}

.check {
    border-color: #e9ecef;
    border-radius: 8px;
    border-width: 2px;

}

.check-selected {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    border-radius: 8px;
}

.img-resposive {
    width: 80%;
    height: 80%;
}

.title-face-select {
    padding: 0px;
    color: #ffff;
}

.title-face {
    padding: 0px;
    color: #151518;
}

.check-img {
    padding-bottom: 5px;
    color: #151518;
}

.check-img-select {
    padding-bottom: 5px;
    color: #fff;
}



@media only screen and (max-width: 579px) {
    .container-center {
        padding: 50px;
    }
}
</style>
