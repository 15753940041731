<template>
  <div class="p-2">
    <v-data-table
      :loading="loadingDenied"
      dense
      :headers="headers"
      :items="dataList"
      height="auto"
      fixed-header
      :search="search"
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
    >
      <template v-slot:top>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <v-text-field
              v-model="search"
              :label="$t('table.search')"
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top v-if="!sending">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="deleteModal(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{$t('table.delete')}}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <div class="card-body pt-10">
      <validation-observer ref="observer1" v-slot="{ invalid }">
        <form @submit.prevent="addMedicalPrescriptionDenied">
          <div class="row">
            <div class="col-md-12">
              <AllProductsComponent
                ref="allProducts"
                :productLabel="$t('label.products')"
                v-on:productChange="productChange"
              />
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-xl-3">
              <validation-provider name="motive" rules="required">
                <v-autocomplete :disabled="sending" no-data-text="Sin resultados" v-model="model.reason" :items="reasonList" :label="$t('label.motive')"/>
              </validation-provider>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-xl-3">
              <validation-provider v-slot="{ errors }" name="amount_denied" rules="required|numeric">
                <v-text-field :disabled="sending" v-model="model.quantity" :error-messages="errors" :label="$t('label.amount_denied')" required/>
              </validation-provider>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-xl-3 pt-7 text-left">
              <button :disabled="invalid || sending" class="btn btn-outline-success" type="submit">
                <i class="ti-plus mr-3"></i> {{$t('global.save_denied')}}
              </button>
            </div>
          </div>
        </form>
      </validation-observer>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogValidate" persistent max-width="500">
        <v-card>
          <v-card-title class="headline">
             {{$t('dispensing.title_modal_delete')}}
          </v-card-title>
          <v-card-text align="justify"> {{$t('dispensing.text_modal_delete')}} </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button :disabled="sending" @click="deleteMedicalPrescriptionDenied()" class="btn btn-outline-success mr-4 ">
                {{$t('global.accept')}}
            </button>
            <button :disabled="sending" @click="dialogValidate = false" class="btn btn-outline-danger">
                {{$t('global.cancel')}}
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import { UtilFront } from "@/core";
import AllProductsComponent from "@/common/select/AllProductsComponent.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { MedicalPrescriptionRequest } from "@/core/request";
import { PRODUCTS_DENIED } from "@/core/DataTableHeadersLocale";


export default {
  /*props: {
    idPrescription: "",
    idUser: "",
    idBranchOffice: "",
  },*/
  data() {
    return {
      headers: PRODUCTS_DENIED(),
      dataList: [],
      search: "",

      idPrescription: "",
      idUser: "",
      idBranchOffice: "",

      /* Formulario */
      idProduct: "",
      reasonList: [],
      dialogValidate: false,
      currentItem: {},
      userBranchOffice: {},
      model: { quantity: "", idReason: "" },

      /* Estados de carga */
      sending: false,
      loading: null,
      loadingDenied:false
    };
  },
  components: {
    AllProductsComponent,
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    listAllMedicalPrescriptionDenied(idPrescription) {
      this.loadingDenied = true;
      MedicalPrescriptionRequest.listAllMedicalPrescriptionDenied({
        medicalPrescription: {
          idPrescription: idPrescription,
        },
        userBranchOffice: {
          idUser: this.idUser,
          idBranchOffice: this.idBranchOffice,
        },
      }).then((response) => {
        let { data } = response.data;
        let { message, success, object } = data;

        if (success) {
          this.dataList = object;
        } else {
          console.log("Error");
        }
      }).catch( (error) => {
          console.log(error);
          this.$emit("alert","error","Error de red");
      }).finally(() =>{
          this.loadingDenied = false;
      });
    },

    deleteModal(item) {
      this.dialogValidate = true;
      this.currentItem = item;
    },

    /* Crud Negados */
    addMedicalPrescriptionDenied() {
        if (!this.sending) {

            this.sending = true;

            MedicalPrescriptionRequest.addMedicalPrescriptionDenied({
                medicalPrescription: {
                    idPrescription: this.idPrescription
                },
                product: {
                    idProduct: this.idProduct,
                },
                quantity : this.model.quantity,
                reason: this.model.reason,
                userBranchOffice: {
                    idUser: this.idUser,
                    idBranchOffice: this.idBranchOffice
                }
            }).then( (response) => {
                let { data } = response.data;
                let { message, success } = data;

                if ( success ) {
                    //Refresco la lista
                    this.listAllMedicalPrescriptionDenied( this.idPrescription );

                    this.clean();

                    this.$toast.success("Producto agregado.");

                } else {
                  this.$emit("alert","error",message);
                  this.$toast.error(message);
                }
            }).catch( (error) => {
                this.$toast.warning("Por el momento no se pudo atender la transacción, por favor intente de nuevo.");
            }).finally(() =>{
                this.sending = false;
            });
        }
    },
    deleteMedicalPrescriptionDenied() {

        if ( !this.sending ) {
            this.sending = true;

            //Muestro la pantalla de cargando
            this.loading = UtilFront.getLoadding();

            MedicalPrescriptionRequest.deleteMedicalPrescriptionDenied({
                medicalPrescription: {
                idPrescription: this.idPrescription,
                },
                product: {
                idProduct: this.currentItem.product.idProduct,
                },
                userBranchOffice: {
                idUser: this.idUser,
                idBranchOffice: this.idBranchOffice,
                },
            }).then((response) => {
                let { data } = response.data;
                let { message, success } = data;

                if (success) {
                    //Refresco la lista
                    this.listAllMedicalPrescriptionDenied(this.idPrescription);
                    this.dialogValidate = false;

                    this.$toast.success("Producto eliminado");
                } else {
                    this.$emit("alert","error",error);
                    this.$toast.error(message);
                }
            }).catch( (error) => {
                this.$emit("alert","error",error);
                this.$toast.error(error);
            }).finally(() => {
                //Elimino la pantalla de cargando
                this.loading.hide();
                this.sending = false;
            });


        }

    },

    /* Funciones de productos */
    productChange(idProduct) {
      this.idProduct = idProduct;
    },
    clean(){
        //Limpio los campos
        this.model.quantity = '';
        this.model.reason = '';
        this.$refs.allProducts.reloadComponentClean();
        this.$refs.observer1.reset();
    },
    reloadComponent( idPrescription,idUser,idBranchOffice){
      this.idUser = idUser;
      this.idBranchOffice = idBranchOffice;
      this.idPrescription = idPrescription;
      if ( this.dataList.length == 0 ) 
        this.listAllMedicalPrescriptionDenied(idPrescription);
    },
    reloadComponentClean(){
      this.clean();
      this.dataList = [];
    }

  },
  created() {
      this.reasonList = [
        { text: "Inventario Nulo", value: "1" },
        //{ text: "Inventario Insuficiente", value:"2"},
        { text: "Caducado", value: "3" },
      ];
  },
  watch: {
        "$i18n.locale": function () {
              this.headers= PRODUCTS_DENIED();
        }
  },
};
</script>

<style scope >
</style>

