<template>
<div class="row container-center">
    <div class="col-md-4 col-sm-4 col-lg-2">
        <div class="card">
            <button v-bind:class="[
            hidden
              ? 'btn btn-warning check-selected orange'
              : 'btn btn-outline-warning check orange_hover',
            ,
          ]" @click="
            saveAnswers(null);
            hiddensOption(true);
          ">
                <img src="@/assets/images/quiz/danger.png" class="img-resposive" />
                <div class="col-md-12 col-sm-12" v-bind:class="[hidden ? 'check-img-select' : 'check-img', ,]">
                    <span v-bind:class="[
                hidden
                  ? 'mdi mdi-checkbox-blank-circle'
                  : 'mdi mdi-checkbox-blank-circle-outline check',
                ,
              ]"></span>
                </div>
                <div class="col-md-12 col-sm-12" v-bind:class="[hidden ? 'title-face-select' : 'title-face', ,]">
                    <p>No</p>
                </div>
            </button>
        </div>
    </div>
    <div v-for="answers of listYes" :key="answers.idAnswerQuestion" class="col-md-4 col-sm-4 col-lg-2">
        <div v-if="answers.answer === 'Si'">
            <div class="card">
                <button v-bind:class="[
              answersQuestion === answers.idAnswerQuestion
                ? 'btn btn-success check-selected success'
                : 'btn btn-outline-success check success_hover',
              ,
            ]" @click="
              saveAnswers(answers.idAnswerQuestion);
              hiddensOption(false);
            ">
                    <img src="@/assets/images/quiz/success.png" class="img-resposive" />
                    <div class="col-md-12 col-sm-12" v-bind:class="[
                answersQuestion === answers.idAnswerQuestion
                  ? 'check-img-select'
                  : 'check-img',
                ,
              ]">
                        <span v-bind:class="[
                  answersQuestion === answers.idAnswerQuestion
                    ? 'mdi mdi-checkbox-blank-circle'
                    : 'mdi mdi-checkbox-blank-circle-outline',
                  ,
                ]"></span>
                    </div>
                    <div class="col-md-12 col-sm-12" v-bind:class="[
                answersQuestion === answers.idAnswerQuestion
                  ? 'title-face-select'
                  : 'title-face',
                ,
              ]">
                        <p>{{ answers.answer }}</p>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-12 col-sm-12 col-lg-12"></div>
    <div v-show="hidden" class="col-md-10 col-sm-10 col-lg-6">
        <v-select v-model="select" outlined :items="optionList" item-text="answer" item-value="idAnswerQuestion" label="Selecciona una opción" @input="saveAnswers($event)"></v-select>
    </div>
</div>
</template>

<script>
export default {
    props: {
        answersQuestion: {
            required: true,
            default: 0,
        },
        answersOption: {
            required: true,
            default: {},
        },
        saveAnswers: Function,
    },
    data() {
        return {
            hidden: false,
            select: {},
        };
    },
    methods: {
        hiddensOption(val) {
            this.hidden = val;
            this.select = ''
        },
    },
    computed: {
        optionList() {
            return this.answersOption.filter(
                (answers) => answers.answer !== 'Si'
            );
        },
        listYes() {
            return this.answersOption.filter(
                (answers) => answers.answer == 'Si'
            );
        }
    }
};
</script>

<style scoped>
.orange {
    background-color: #f4a261 !important;
    border-color: #f4a261 !important;
}

.orange_hover:hover {
    background-color: #f4a261 !important;
    border-color: #f4a261 !important;
}

.warning {
    background-color: #ffce52 !important;
    border-color: #ffce52 !important;
}

.warning_hover:hover {
    background-color: #ffce52 !important;
    border-color: #ffce52 !important;
}

.success {
    background-color: #95d5b2 !important;
    border-color: #95d5b2 !important;
}

.success_hover:hover {
    background-color: #95d5b2 !important;
    border-color: #95d5b2 !important;
}

.container-center {
    justify-content: center;
    align-items: center;
    display: flex;
}

.check {
    border-color: #e9ecef;
    border-radius: 8px;
    border-width: 2px;
}

.check-selected {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    border-radius: 8px;
}

.img-resposive {
    width: 80%;
    height: 80%;
}

.title-face-select {
    padding: 0px;
    color: #ffff;
}

.title-face {
    padding: 0px;
    color: #151518;
}

.check-img {
    padding-bottom: 5px;
    color: #151518;
}

.check-img-select {
    padding-bottom: 5px;
    color: #fff;
}

@media only screen and (max-width: 579px) {
    .container-center {
        padding: 50px;
    }
}
</style>
