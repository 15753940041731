<template>
  <div>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="form">
        <div class="row">
          <div class="col-md-4">
            <validation-provider
              v-slot="{ errors }"
              name="name"
              rules="required|max:100|alpha_spaces"
            >
              <v-text-field
                v-model="name"
                :counter="100"
                :error-messages="errors"
                oninput="this.value = this.value.toUpperCase()"
                :label="$t('label.name')"
                required
              />
            </validation-provider>
          </div>
          <div class="col-md-8">
            <validation-provider
              v-slot="{ errors }"
              name="lastname"
              rules="required|max:200|alpha_spaces"
            >
              <v-text-field
                v-model="lastName"
                :counter="200"
                oninput="this.value = this.value.toUpperCase()"
                :error-messages="errors"
                :label="$t('label.lastname')"
                required
              />
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <validation-provider
              v-slot="{ errors }"
              name="professiona_license"
              rules="required|max:100"
            >
              <v-text-field
                v-model="professionalId"
                :counter="100"
                oninput="this.value = this.value.toUpperCase()"
                :error-messages="errors"
                :label="$t('label.professiona_license')"
                required
              />
            </validation-provider>
          </div>
        </div>

        <div class="row" v-show="isModal">
          <div class="col-md-12 text-right">
            <button
              class="btn btn-outline-success mr-4"
              type="submit"
              :disabled="invalid || sending"
            >
              <i class="feather icon-save mr-1"></i> {{ $t(titleButton) }}
            </button>
            <button
              class="btn btn-outline-danger"
              type="button"
              @click="closeModalDoctor"
              :disabled="sending"
            >
              {{ $t("global.cancel") }}
            </button>
          </div>
        </div>

        <div class="row" v-show="!isModal">
          <div class="col-md-12 text-right">
            <button
              class="btn btn-outline-success"
              type="submit"
              :disabled="invalid || sending"
            >
              <i class="feather icon-save mr-1"></i> {{ $t(titleButton) }}
            </button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { UtilFront } from "@/core";
import { DoctorRequest } from "@/core/request";

import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  props: {
    idUser: "",
    idBranchOffice: "",
    isModal: false,
  },
  data() {
    return {
      idDoctor: "",
      name: "",
      lastName: "",
      professionalId: "",
      sending: false,
      loading: null,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    titleButton() {
      return this.idDoctor != ""
        ? "global.update_doctor"
        : "global.save_doctor";
    },
  },
  methods: {
    form() {
      if (this.idDoctor != "") {
        this.updateDoctor();
      } else {
        this.addDoctor();
      }
    },
    addDoctor() {
      this.sending = true;
      this.loading = UtilFront.getLoadding();
      let doctor = {
        name: this.name,
        lastName: this.lastName,
        professionalId: this.professionalId,
        userBranchOffice: {
          idBranchOffice: this.idBranchOffice,
          idUser: this.idUser,
        },
      };
      DoctorRequest.addDoctor(doctor)
        .then((response) => {
          let { success, message, data } = response.data.data;
          if (success) this.$emit("alert", "success", "Add_Success_0001", data);
          else this.$emit("alert", "error", message, {});
        })
        .catch((error) => {
          console.log(error);
          this.$emit("alert", "internal");
        })
        .finally(() => {
          this.loading.hide();
          this.sending = false;
        });
    },
    updateDoctor() {
      this.sending = true;
      this.loading = UtilFront.getLoadding();
      let doctorUpdate = {
        idDoctor: this.idDoctor,
        name: this.name,
        lastName: this.lastName,
        professionalId: this.professionalId,
        userBranchOffice: {
          idBranchOffice: this.idBranchOffice,
          idUser: this.idUser,
        },
      };
      DoctorRequest.updateDoctor(doctorUpdate)
        .then((response) => {
          let { success, message } = response.data.data;
          if (success) this.$emit("alert", "success", "Update_Success_0001");
          else this.$emit("alert", "error", message);
        })
        .catch((error) => {
          console.log(error);
          this.$emit("alert", "internal");
        })
        .finally(() => {
          this.loading.hide();
          this.sending = false;
        });
    },
    reloadComponentUpdate(object) {
      this.idDoctor = object.idDoctor;
      this.name = object.name;
      this.lastName = object.lastName;
      this.professionalId = object.professionalId;
    },
    reloadComponentClean() {
      this.idDoctor = "";
      this.name = "";
      this.lastName = "";
      this.professionalId = "";

      this.$refs.observer.reset();
    },
    closeModalDoctor() {
      this.reloadComponentClean();
      this.$emit("closeModalDoctor");
    },
  },
};
</script>
