var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('v-data-table',{attrs:{"loading":_vm.loadingDenied,"dense":"","headers":_vm.headers,"items":_vm.dataList,"height":"auto","fixed-header":"","search":_vm.search,"footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"}),_c('div',{staticClass:"col-md-6"},[_c('v-text-field',{attrs:{"label":_vm.$t('table.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(!_vm.sending)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('table.delete')))])]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"card-body pt-10"},[_c('validation-observer',{ref:"observer1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.addMedicalPrescriptionDenied.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('AllProductsComponent',{ref:"allProducts",attrs:{"productLabel":_vm.$t('label.products')},on:{"productChange":_vm.productChange}})],1),_c('div',{staticClass:"col-xs-12 col-sm-6 col-md-6 col-xl-3"},[_c('validation-provider',{attrs:{"name":"motive","rules":"required"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.sending,"no-data-text":"Sin resultados","items":_vm.reasonList,"label":_vm.$t('label.motive')},model:{value:(_vm.model.reason),callback:function ($$v) {_vm.$set(_vm.model, "reason", $$v)},expression:"model.reason"}})],1)],1),_c('div',{staticClass:"col-xs-12 col-sm-6 col-md-6 col-xl-3"},[_c('validation-provider',{attrs:{"name":"amount_denied","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.sending,"error-messages":errors,"label":_vm.$t('label.amount_denied'),"required":""},model:{value:(_vm.model.quantity),callback:function ($$v) {_vm.$set(_vm.model, "quantity", $$v)},expression:"model.quantity"}})]}}],null,true)})],1),_c('div',{staticClass:"col-xs-12 col-sm-6 col-md-6 col-xl-3 pt-7 text-left"},[_c('button',{staticClass:"btn btn-outline-success",attrs:{"disabled":invalid || _vm.sending,"type":"submit"}},[_c('i',{staticClass:"ti-plus mr-3"}),_vm._v(" "+_vm._s(_vm.$t('global.save_denied'))+" ")])])])])]}}])})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.dialogValidate),callback:function ($$v) {_vm.dialogValidate=$$v},expression:"dialogValidate"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('dispensing.title_modal_delete'))+" ")]),_c('v-card-text',{attrs:{"align":"justify"}},[_vm._v(" "+_vm._s(_vm.$t('dispensing.text_modal_delete'))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('button',{staticClass:"btn btn-outline-success mr-4 ",attrs:{"disabled":_vm.sending},on:{"click":function($event){return _vm.deleteMedicalPrescriptionDenied()}}},[_vm._v(" "+_vm._s(_vm.$t('global.accept'))+" ")]),_c('button',{staticClass:"btn btn-outline-danger",attrs:{"disabled":_vm.sending},on:{"click":function($event){_vm.dialogValidate = false}}},[_vm._v(" "+_vm._s(_vm.$t('global.cancel'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }