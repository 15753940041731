<template>
<div>
    <v-dialog v-model="dialog" persistent max-width="500">
        <v-card>
            <v-card-title class="headline">
                {{ title }}
            </v-card-title>
            <v-card-text align="justify">
                {{ confirmTitle }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <button class="btn btn-outline-success m-2" @click="confirm">
                    {{ $t("global.yes_continue") }}
                </button>
                <button class="btn btn-outline-danger" @click="dialog = false">
                    {{ $t("global.cancel") }}
                </button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    props: {
        status: 0,
    },
    computed: {
        title() {
            let title = "";
            if (this.status === 0) {
                title = this.$t("dispensing.title_0");
            } else if (this.status === 1) {
                title = this.$t("dispensing.title_1");
            } else if (this.status === 2) {
                title = this.$t("dispensing.title_2");
            }
            return title;
        },
        confirmTitle() {
            let title = "";
            if (this.status === 0) {
                title = this.$t("dispensing.title_confirm_0");
            } else if (this.status === 1) {
                title = this.$t("dispensing.title_confirm_1");
            } else if (this.status === 2) {
                title = this.$t("dispensing.title_confirm_2");
            }
            return title;
        },
    },
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        reloadComponent(item) {
            this.dialog = true;
        },
        reloadComponentClose() {
            this.dialog = false;
        },
        confirm() {
            this.$emit("confirm");
        },
    },
};
</script>
